import Image from 'next/image';
import { sanitize } from 'isomorphic-dompurify';

import { HeroBlockProps } from './HeroBlock';

const HeroBlockGuidesMarkup = ({
  anchor,
  className,
  title,
  subtitle,
  text,
  image,
}: HeroBlockProps) => {
  return (
    <div
      id={anchor}
      className={`${className ?? ''} bg-navy pt-[6.4rem] lg:pt-[12.8rem]`}
    >
      <div className="bg-[url('../public/images/hero_block_mobile.png')] bg-no-repeat bg-contain bg-[bottom_-28vw_center] md:bg-[bottom_0_right_-10vw] lg:bg-right-bottom md:bg-[url('../public/images/hero_block.png')] pb-[34vw] md:pb-[4.8rem] lg:pb-[12.8rem]">
        <div className="mx-auto max-w-page">
          <div className="md:w-[80vw] lg:w-[75vw] lg:max-w-[84.8rem] px-[4rem]">
            {title ? (
              <h1 className="text-white text-[5.4rem] md:text-[9rem] leading-[.8] [&:has(+h2)]:mb-[4.8rem] [&:has(+p)]:mb-[4.8rem]">
                {title}
              </h1>
            ) : null}
            {subtitle ? (
              <h2 className="text-white text-[2.8rem] md:text-[3.6rem] leading-[1] [&:has(+div)]:mb-[1.2rem]">
                {subtitle}
              </h2>
            ) : null}
            {text ? (
              <div
                className="text-white text-med leading-[1.8]"
                dangerouslySetInnerHTML={{
                  __html: sanitize(text),
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      {image?.url ? (
        <div className="mx-auto max-w-theme pb-[4rem] lg:px-[4rem]">
          <div className="lg:overflow-hidden lg:rounded-[8px]">
            <Image
              width="1440"
              height="800"
              src={image.url}
              title={image?.title}
              alt={image?.alt}
            />
          </div>

          {image?.caption ? (
            <div className="pt-[1.6rem] mx-auto max-w-page px-[4rem] lg:px-0">
              <p
                className="text-white [&>a]:text-green [&>a]:font-bold max-w-[84.7rem]"
                dangerouslySetInnerHTML={{
                  __html: sanitize(image.caption),
                }}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default HeroBlockGuidesMarkup;
