import { TrackableEvents } from "@skillshare/ui-components/shared/events";
import { useEventTracker } from "@skillshare/ui-components/shared/hooks/use-event-tracker";

export const useTrackEventClickedBlogClassCTA = () => {
  const { trackEvent } = useEventTracker();

  return function emit() {
    trackEvent(TrackableEvents.ClickedBlogClassCTA, {
      "page-title": document.title,
    });
  };
};
