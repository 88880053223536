import Image from 'next/image';

import cardSquiggles from 'public/images/card-squiggles.svg';

const CardImage = ({ thumb, title, isHero = false }) => {
  const heightClasses = isHero
    ? 'md:min-h-[324px] theme:min-h-[376px]'
    : 'md:min-h-[220px] lg:min-h-[180px] xl:min-h-[210]';

  const imageChildren = thumb ? (
    <Image
      src={thumb}
      alt={title || ''}
      layout="fill"
      loading="lazy"
      className="h-full left-0 object-cover absolute top-0 w-full"
    />
  ) : (
    <div className="bg-canvas h-full left-0 object-cover absolute top-0 w-full">
      <Image
        src={cardSquiggles}
        alt={title || ''}
        layout="fill"
        loading="lazy"
        className="h-full left-0 object-cover absolute top-0 w-full"
      />
    </div>
  );

  const imageClasses = `block h-full min-h-[260px] ${heightClasses} relative`;

  return (
    <div data-testid="card-image" className={`${imageClasses}`}>
      {imageChildren}
    </div>
  );
};

export default CardImage;
