import { useState, useEffect } from 'react';

import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';
import NewsletterSignupBlock from '../components/Blocks/NewsletterSignupBlock';

const NewsletterSignupBlockProxy = ({ renderedHtml }) => {
  const [newsLetterSignupProps, setProps] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      setProps(getObjDataFromRenderedHtml(renderedHtml));
    }
    return null;
  }, [renderedHtml]);

  return newsLetterSignupProps ? (
    <NewsletterSignupBlock {...newsLetterSignupProps} />
  ) : null;
};

NewsletterSignupBlockProxy.displayName = 'AcfNewsletterSignupBlock';

export default NewsletterSignupBlockProxy;
