import { useTranslation } from 'next-i18next';

import Button from 'components/Buttons/Button';
import { useTrackEventClickedBlogClassCTA } from 'hooks/useTrackEventClickedBlogClassCTA';

export interface StickyCtaData {
  headline: string;
  button: {
    url: string;
    title: string;
    target: string;
  };
  values: { value: string }[];
}

interface StickyCtaInterface {
  hideMobile?: boolean;
  mobileOnly?: boolean;
  stickyCtaData?: StickyCtaData;
  top?: string;
}

const StickyCTA = ({
  hideMobile,
  mobileOnly,
  stickyCtaData,
  top,
}: StickyCtaInterface) => {
  const { t } = useTranslation('common');
  const onClickBlogClassCTA = useTrackEventClickedBlogClassCTA();

  const fallBackData: StickyCtaData = {
    headline: t('stickyTitle'),
    button: {
      url: 'https://www.skillshare.com/signup',
      title: t('stickyCTA'),
      target: '',
    },
    values: [
      { value: t('stickyValue1') },
      { value: t('stickyValue2') },
      { value: t('stickyValue3') },
    ],
  };

  const data = stickyCtaData || fallBackData;

  return (
    <div
      data-testid="sticky-cta"
      className={`bg-white shadow-sm rounded-theme py-[32px] px-[16px] w-full lg:mt-[24px] lg:sticky ${top ?? 'top-[126px]'} mx-auto max-w-[380px] ${hideMobile ? 'hidden lg:block' : 'block'} ${mobileOnly ? 'lg:hidden' : 'block'}`}
    >
      <h3 className="mb-[2.4rem] text-center">{data.headline}</h3>
      <Button
        style="mb-[2.4rem] text-center w-full"
        external={true}
        href={data.button?.url}
        onClick={onClickBlogClassCTA}
        target={data.button.target}
      >
        {data.button.title}
      </Button>
      <ul>
        {data.values?.length &&
          data.values.map((cur, ind) => {
            return (
              <li
                key={ind}
                className="[&:not(:last-child)]:mb-[.8rem] list-disc text-base ml-[16px]"
              >
                {cur.value}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default StickyCTA;
