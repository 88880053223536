import React, { MutableRefObject } from 'react';
import Link from 'next/link';
import { sanitize } from 'isomorphic-dompurify';

import { Post as PostType } from 'gql/graphql';
import { mapPostCard } from 'helpers/mapPostCard';
import { getFormattedUrl } from 'libs/getFormattedUrl';

import ContentAssetPill from './shared/ContentAssetPill';
import PostCardMeta, { IMAGE_LAYOUT_TOP } from './shared/PostCardMeta';
import CardImage from './shared/CardImage';

export interface PostCardProps {
  bgColor?: string;
  innerRef?: MutableRefObject<HTMLDivElement>;
  isSingle?: boolean;
  locale: Blog.LocaleType;
  post: PostType;
  showPill?: boolean;
  styles?: string;
}

const PostCard = ({
  post,
  locale,
  innerRef = null,
  isSingle = false,
  showPill = true,
  styles = '',
  bgColor = '',
}: PostCardProps) => {
  if (!post) return null;
  const { contentAsset, thumb, title, uri } = mapPostCard(post);

  const IsSingleWrap = ({ children }) => {
    return isSingle ? (
      <>{children}</>
    ) : (
      <Link
        className="absolute top-0 left-0 h-full w-full"
        href={getFormattedUrl(uri, locale)}
        title={title}
        passHref
      >
        <a className="block h-full w-full">{children}</a>
      </Link>
    );
  };

  return (
    <div
      ref={innerRef}
      data-testid="post-card"
      className={`post-card col-span-12 relative md:col-span-6 lg:col-span-4 bg-white rounded-[8px] shadow-[0_2px_4px_0_rgba(180,184,184,0.5)] overflow-hidden ${styles} ${bgColor}`}
    >
      <IsSingleWrap>
        <div className="post-card__thumb relative">
          {showPill && (
            <ContentAssetPill
              contentAsset={contentAsset}
              extraClasses="absolute top-3 left-3 z-10"
            />
          )}
          <CardImage thumb={thumb} title={title} />
        </div>
        <PostCardContent isSingle={isSingle} post={post} locale={locale} />
      </IsSingleWrap>
    </div>
  );
};
export default PostCard;

const PostCardContent = ({ isSingle, post, locale }: PostCardProps) => {
  const { excerpt, title } = post;

  return (
    <div className="post-card-content p-[16px] pt-0 flex flex-col gap-[13px]">
      <PostCardMeta
        post={post}
        locale={locale}
        imageLayout={IMAGE_LAYOUT_TOP}
      />
      <h2 className="post-card-content__title font-bold text-[18px] leading-[20px] spacing-0.5 line-clamp-3">
        <span
          className={`${!isSingle ? 'cursor-pointer' : ''} block text-navy`}
          dangerouslySetInnerHTML={{ __html: sanitize(title) }}
        />
      </h2>
      <div
        className="post-card-content__excerpt font-normal  text-[16px] leading-[18.32px] text-navy h-[54.96px] line-clamp-3"
        dangerouslySetInnerHTML={{ __html: sanitize(excerpt) }}
      />
    </div>
  );
};
