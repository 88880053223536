import { useReducer, createContext, useContext } from 'react';

export interface PostContextType {
  state: PostContext;
  dispatch: React.Dispatch<Actions>;
}

export interface PostContext {
  contentAssetType?: string;
  trackAsGatedContent?: boolean;
}

type Actions = SetPost;

type SetPost = {
  type: 'SET_POST';
  payload: PostContext;
};

const init: PostContext = {
  // Add your initial state here
  contentAssetType: null,
  trackAsGatedContent: null,
};

const reducer = (state: PostContext, action: Actions): PostContext => {
  const newState = { ...state };

  switch (action.type) {
    case 'SET_POST': {
      newState.contentAssetType = action.payload.contentAssetType;
      newState.trackAsGatedContent = action.payload.trackAsGatedContent;
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export const PostContext = createContext<PostContextType | undefined>(
  undefined
);

export const PostProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, init);
  const value = { state, dispatch };
  return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
};

export const usePostContext = () => {
  const context = useContext(PostContext);
  if (context === undefined) {
    throw new Error('usePostContext must be used within a PostProvider');
  }
  return context;
};
