const pathsToRedirectToHome = ['/resources', '/articles', '/news'];

export function getFormattedUrl(
  localSlug: string,
  locale: Blog.LocaleType,
  relative: boolean = true
) {
  if (!localSlug) {
    return '';
  }

  if (
    locale !== 'en' &&
    pathsToRedirectToHome.some((path) => localSlug.includes(path))
  ) {
    return '/';
  }

  let slug = localSlug;

  if (slug.startsWith('/')) {
    slug = slug.substring(1);
  }

  if (slug !== '' && !slug.endsWith('/')) {
    slug += '/';
  }

  return relative
    ? `/${locale}/blog/${slug}`
    : `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${locale}/blog/${slug}`;
}
