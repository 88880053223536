import Heading from 'components/Typography/Heading';
import Paragraph from 'components/Typography/Paragraph';
import Button from 'components/Buttons/Button';
import { themes, defaultTheme, themePadding } from 'styles/shared/defaults';

interface Infographic {
  image: string;
  heading: string;
  text: string;
}

export interface InfographicBlockProps extends Blog.BlockProps {
  color_theme: Blog.ThemeColorOptions;
  heading: string;
  infographics: Infographic[];
  cta: Blog.ACFLink | '';
}

const InfographicBlock = ({
  anchor,
  className,
  color_theme,
  heading,
  infographics,
  cta,
}: InfographicBlockProps) => {
  const theme = themes[color_theme] || defaultTheme;
  return (
    <section
      id={anchor}
      data-testid="infographic-block"
      className={`${className ?? ''} py-24 ${themePadding.pageXPadding} ${theme.bgColor}`}
    >
      <div className="max-w-page mx-auto">
        {heading && (
          <Heading
            level="h2"
            text={heading}
            style={`${theme.textColor} mb-16 text-center`}
          />
        )}
        <div className="md:grid gap-12 grid-flow-col justify-center">
          {infographics?.length &&
            infographics.map((stat, index) => (
              <InfographicComponent
                key={index}
                stat={stat}
                color={theme.textColor}
              />
            ))}
        </div>

        {cta && (
          <div className="text-center mt-8">
            <Button
              size="large"
              theme={theme.buttonTheme}
              href={cta.url}
              target={cta.target}
            >
              {cta.title}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default InfographicBlock;

interface InfographicProps {
  stat: Infographic;
  color: string;
}

const InfographicComponent = ({ stat, color }: InfographicProps) => {
  return (
    <div
      data-testid="infographic"
      className="md:max-w-[357px] mx-auto text-center [&:not(:last-child)]:mb-12 md:!mb-0"
    >
      <div className="mb-8 md:max-w-[320px] mx-auto relative overflow-hidden rounded-theme">
        <img src={stat.image} width="100%" height="auto" alt={stat?.heading} />
      </div>
      {stat?.heading && (
        <Heading level="h3" text={stat.heading} style={`${color} mb-2`} />
      )}
      {stat?.text && (
        <Paragraph
          text={stat.text}
          html={true}
          style={`${color} !text-base !leading-[1.2]`}
        />
      )}
    </div>
  );
};
