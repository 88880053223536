// eslint-disable-next-line import/no-unresolved
import { WordPressBlocksViewer } from '@faustwp/blocks';

import { Page as PageType, Post as PostType } from 'gql/graphql';

const flatListToHierarchical = (
  data = [],
  {
    idKey = 'id',
    parentKey = 'parentClientId',
    childrenKey = 'innerBlocks',
  } = {}
) => {
  const tree = [];
  const childrenOf = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem);
  });

  return tree;
};

interface EditorBlocksType {
  editorBlocks?: Array<object> | null;
}

type GetContentFromBlocksProps =
  | (PostType & EditorBlocksType)
  | (PageType & EditorBlocksType);

export function getContentFromBlocks(post: GetContentFromBlocksProps) {
  if (post.editorBlocks === null) {
    return null;
  }

  return (
    <WordPressBlocksViewer
      blocks={flatListToHierarchical(post.editorBlocks ?? [])}
    />
  );
}
