import cookies from 'js-cookie';

import { AuthenticationProviderProps } from '@skillshare/ui-components/components/providers';

/**
 * Gets the current authentication context.
 *
 * @todo Refactor this function after the `useCookies` hook has been removed.
 */
export function getAuthContext(reqCookies?: AuthenticationProviderProps): Record<string, string> {
    const YII_CSRF_TOKEN =
        reqCookies && reqCookies.YII_CSRF_TOKEN ? reqCookies.YII_CSRF_TOKEN : cookies.get('YII_CSRF_TOKEN');
    const device_session_id =
        reqCookies && reqCookies.device_session_id ? reqCookies.YII_CSRF_TOKEN : cookies.get('device_session_id');

    return {
        YII_CSRF_TOKEN,
        device_session_id,
    };
}
