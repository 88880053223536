import StickyCTA, { StickyCtaData } from 'components/CTAs/StickyCTA';
import AuthorshipCard from 'components/Cards/AuthorshipCard';
import AuthorshipCardCarousel from 'components/Cards/AuthorshipCardCarousel';

import DynamicTOC from './DynamicTOC';

export interface StickyColumnProps {
  authorship?: Blog.AuthorType[];
  parsedContent: JSX.Element;
  stickyCtaData?: StickyCtaData;
}

const StickyColumn = ({
  authorship,
  parsedContent,
  stickyCtaData,
}: StickyColumnProps) => {
  return (
    <div
      data-testid="sticky-column"
      className="sticky-column grid grid-cols-1 gap-4 md:grid-cols-2 lg:items-center mt-[24px] lg:mt-0 justify-center lg:block lg:pb-[4rem] lg:ml-[2rem] lg:order-1 mb-[32px] lg:mb-0 mx-auto w-full lg:max-w-[380px] lg:w-[280px] xl:w-[400px]"
    >
      {authorship && authorship.length ? (
        <div className="lg:mt-[24px]">
          {authorship.length > 1 ? (
            <AuthorshipCardCarousel authors={authorship} />
          ) : (
            <AuthorshipCard author={authorship[0]} />
          )}
        </div>
      ) : (
        ''
      )}
      {parsedContent && <DynamicTOC parsedContent={parsedContent} />}
      <StickyCTA hideMobile stickyCtaData={stickyCtaData} />
    </div>
  );
};

export default StickyColumn;
