import { useTranslation } from 'next-i18next';

import Button from 'components/Buttons/Button';

const MobileMenuCTA = () => {
  const { t } = useTranslation('common');

  return (
    <div className="lg:hidden bg-white text-center tracking-[1px] py-[2.5rem] hover:bg-green hover:text-purple focus:bg-green focus:text-purple">
      <Button
        href="https://www.skillshare.com/membership/checkout?coupon=blog1month&via=blog-nav"
        external={true}
        style="uppercase"
      >
        {t('joinCTA')}
      </Button>
    </div>
  );
};

export default MobileMenuCTA;
