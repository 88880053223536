import { Post as PostType } from 'gql/graphql';
import Container from 'components/Layout/Container';
import SocialShare from 'components/Post/SocialShare';
import StickyColumn from 'components/Post/StickyColumn';
import PostContent from 'components/Post/PostContent';
import StickyCTA, { StickyCtaData } from 'components/CTAs/StickyCTA';
import PostCardHero from 'components/Cards/PostCardHero';

import { getContentFromBlocks } from '../../helpers/getContentFromBlocks';
import BreadCrumbs from '../Navigation/BreadCrumbs';

interface PostComponentInterface {
  asPreview: boolean;
  post: PostType;
  locale: Blog.LocaleType;
}

const Post = ({ asPreview, post, locale }: PostComponentInterface) => {
  const og = post?.seo?.opengraphUrl;
  const parsedContent = getContentFromBlocks(post);
  return (
    <div data-testid="post-component">
      <Container>
        <BreadCrumbs
          locale={locale}
          post={post as PostType}
          styles="mt-[40px] mb-[12px]"
        />
        <PostCardHero locale={locale} post={post} isSingle />
        <div className="max-w-theme mx-auto w-full lg:items-stretch lg:flex">
          <StickyColumn
            authorship={
              post?.authorship &&
              typeof post.authorship === 'object' &&
              (post?.authorship as Blog.AuthorType[])?.filter(
                (cur) => cur.type === 'contributor' || cur.type === 'reviewer'
              )
            }
            parsedContent={parsedContent}
            stickyCtaData={post?.stickyCta as StickyCtaData | undefined}
          />
          <div className="lg:mt-[4rem] lg:pb-[4rem] lg:pr-[1.6rem] lg:w-[75px]">
            <SocialShare url={og} sticky={true} />
          </div>
          <PostContent
            asPreview={asPreview}
            coauthors={
              post?.authorship &&
              typeof post.authorship === 'object' &&
              (post?.authorship as Blog.AuthorType[]).filter(
                (author) =>
                  author.type === 'author' || author.type === 'coauthor'
              )
            }
            parsedContent={parsedContent}
            author={post?.author?.node?.name}
            slug={og}
          />
        </div>
        <div className="mb-[64px]">
          <StickyCTA
            stickyCtaData={post?.stickyCta as StickyCtaData | undefined}
            top="top-0"
            mobileOnly
          />
        </div>
      </Container>
    </div>
  );
};

export default Post;
