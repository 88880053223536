import { useState, useEffect } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const extractImageUrls = (htmlString: string): Array<string> => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');
  const imageTags = htmlDoc.querySelectorAll('img');
  const imageUrls = Array.from(imageTags).map((img) => img.src);

  return imageUrls;
};

const TiledGalleryBlock = ({ renderedHtml }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (renderedHtml) {
      setImages(extractImageUrls(renderedHtml));
    }
  }, [renderedHtml]);

  return images.length ? (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
      <Masonry>
        {images.map((image) => (
          <img key={image} src={image} alt="" />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  ) : null;
};

TiledGalleryBlock.displayName = 'JetpackTiledGallery';

export default TiledGalleryBlock;
