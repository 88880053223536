import { useState, useEffect } from 'react';

import SmallStatBlock from 'components/Blocks/SmallStatBlock';

import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';

const SmallStatBlockProxy = ({ renderedHtml }) => {
  const [statsProps, setStatsProps] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      setStatsProps(getObjDataFromRenderedHtml(renderedHtml));
    }
  }, [renderedHtml]);

  return <SmallStatBlock {...statsProps} />;
};

SmallStatBlockProxy.displayName = 'AcfSmallStatBlock';

export default SmallStatBlockProxy;
