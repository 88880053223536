import React, { useEffect, useState } from 'react';
import Link from 'next/link';

import { Post as PostType } from 'gql/graphql';

import IconCaret from '../Icons/IconCaret';
import IconHome from '../Icons/IconHome';
import {
  BreadcrumbPath,
  getContentTypeAssetPath,
  getContentTypeAssetPlural,
} from '../../helpers/getContentTypeAssetPath';

export interface BreadCrumbsProps {
  contentAssetType?: string;
  locale: Blog.LocaleType;
  post?: PostType;
  styles?: string;
}

const BreadCrumbs = ({
  contentAssetType,
  locale,
  post = null,
  styles = '',
}: BreadCrumbsProps) => {
  if (locale !== 'en' || (post && !post?.contentAssetType)) {
    return null;
  }

  const blogUrl = `/${locale}/blog`;
  const textStyle =
    'font-normal text-navy size-[14px] leading-[16.03px] spacing-[0.02em]';

  const [paths, setPaths] = useState<BreadcrumbPath[]>([]);

  useEffect(() => {
    const newPaths: Array<BreadcrumbPath> = [];

    if (contentAssetType) {
      newPaths.push({
        label: getContentTypeAssetPlural(contentAssetType),
        url: null,
      });
    }

    if (post) {
      const contentTypeAssetPath = getContentTypeAssetPath(
        post.contentAssetType
      );

      newPaths.push({
        label: contentTypeAssetPath.label,
        url: blogUrl + contentTypeAssetPath.url,
      });

      newPaths.push({
        label: post.title,
        url: null,
      });
    }

    setPaths(newPaths);
  }, [blogUrl, contentAssetType, post]);

  return (
    <nav
      className={`flex items-center space-x-[12px] w-full lg:w-1/2 overflow-hidden whitespace-nowrap ${styles}`}
    >
      <Link href={blogUrl}>
        <a className={textStyle} data-testid="icon-home">
          <IconHome />
        </a>
      </Link>
      {paths.map((path, index) => (
        <React.Fragment key={index}>
          <IconCaret />
          {path.url ? (
            <a
              data-testid="breadcrumb-second-level"
              href={path.url}
              className={textStyle}
            >
              {path.label}
            </a>
          ) : (
            <span
              data-testid="breadcrumb-current"
              className={`w-[20ch] lg:w-full truncate ${textStyle}`}
            >
              {path.label}
            </span>
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default BreadCrumbs;
