import Image from 'next/image';
import { sanitize } from 'isomorphic-dompurify';
import Link from 'next/link';

import { themePadding } from 'styles/shared/defaults';

import { HeroBlockProps } from './HeroBlock';

const HeroBlockCTAMarkup = (props: HeroBlockProps) => {
  const { anchor, className, title, text, theme, image, cta } = props;
  const buttonText = 'Download the Guide'; // TODO: make it dynamic from backend
  const buttonBgColor = () => {
    const colorThemes = {
      white: 'bg-green text-navy',
      navy: 'bg-green text-navy',
      green: 'bg-black text-green',
      violet: 'bg-green text-navy',
    };

    return colorThemes[theme];
  };
  const textColor =
    theme === 'navy' || theme === 'violet' ? 'text-white' : 'text-navy';
  const buttonStyle = `${buttonBgColor()} font-bold text-[1.8rem] px-[7.1rem] py-[1.4rem] rounded-[4px] min-w-[180px] mt-[3.2rem] block md:inline-block text-center mx-[3.2rem] md:mx-0`;

  return (
    <div
      id={anchor}
      className={`${className ?? ''} hero-block-component bg-${theme} py-[6rem] ${themePadding.pageXPadding}`}
    >
      <div className="mx-auto max-w-page grid grid-cols-1 md:grid-cols-[1fr_305px] lg:grid-cols-[1fr_340px] xl:grid-cols-[1fr_547px] gap-[3rem] lg:gap-[5.8rem] xl:gap-[6.8rem] items-center">
        <div className="order-2 md:order-1">
          {title ? (
            <h1
              className={`${textColor} text-[3.6rem] sm:text-[4.4rem] md:text-[3.6rem] xl:text-[4.8rem] leading-[1.2] md:leading-[1] lg:leading-[1.3] xl:leading-[1.17]`}
            >
              {title}
            </h1>
          ) : null}
          {text ? (
            <div
              className={`${textColor} text-[1.6rem] sm:text-[1.5rem] leading-[1.35] mt-[1.2rem]`}
              dangerouslySetInnerHTML={{
                __html: sanitize(text),
              }}
            />
          ) : null}
          {buttonText ? (
            <Link href={`${cta?.scroll_to_id}`}>
              <a className={buttonStyle} data-testid="hero-block-cta">
                {cta?.text}
              </a>
            </Link>
          ) : null}
        </div>
        {image?.url ? (
          <div className="mx-auto max-w-[547px] max-h-[442px] order-1 md:order-2">
            <Image
              width="547"
              height="422"
              src={image.url}
              title={image?.title}
              alt={image?.alt}
              objectFit="contain"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HeroBlockCTAMarkup;
