import { filterContentTypes } from '../components/Abstracts/Defaults';
export interface BreadcrumbPath {
  label: string;
  url?: string;
}

const getContentTypeAssetPlural = (contentTypeAsset: string | null) => {
  if (!contentTypeAsset) return null;

  return contentTypeAsset.endsWith('s')
    ? contentTypeAsset
    : contentTypeAsset + 's';
};

const getContentTypeAssetUrl = (pluralizedCTA: string) => {
  const loweredCTA = pluralizedCTA.toLowerCase();
  const ctaToReplaceWithResources = filterContentTypes.map((e) => {
    if (!e) return '';
    const lcElement = e.toLowerCase();
    return lcElement.endsWith('s') ? lcElement : lcElement + 's';
  });

  if (ctaToReplaceWithResources.includes(loweredCTA)) return '/resources';

  return (
    '/' +
    loweredCTA
      .trim()
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-{2,}/g, '-')
  );
};

const getContentTypeAssetPath = (contentTypeAsset: string): BreadcrumbPath => {
  if (!contentTypeAsset) return null;
  const pluralizedCTA = getContentTypeAssetPlural(contentTypeAsset);

  return {
    label: pluralizedCTA,
    url: getContentTypeAssetUrl(pluralizedCTA),
  };
};

export { getContentTypeAssetPlural, getContentTypeAssetPath };
