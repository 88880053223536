import Image from 'next/image';
import { sanitize } from 'isomorphic-dompurify';

import { themePadding } from 'styles/shared/defaults';

interface Layout {
  title: string;
  text: string;
  image: Blog.ACFImage;
}

export interface ZLayoutBlockProps extends Blog.BlockProps {
  layouts: Layout[];
}

const ZLayoutBlock = (props: ZLayoutBlockProps) => {
  if (!props?.layouts || !props?.layouts?.length) return null;
  return (
    <section
      data-testid="z-layout-block"
      id={props.anchor}
      className={`${themePadding.pageXPadding} ${props.className ?? ''} bg-canvas`}
    >
      <div className={`grid gap-y-[3.75rem] mx-auto max-w-page py-[5rem]`}>
        {props.layouts.map((item, index) => {
          const { title, text, image } = item;
          return (
            <div
              data-testid="z-layout-layout"
              className="group grid md:grid-cols-2 gap-[3rem] md:gap-[5.625rem]"
              key={`z-layout-row-${index}`}
            >
              <div className="self-center order-1 group-even:md:order-2">
                {title ? (
                  <h2 className="text-navy text-[2.5rem]">{title}</h2>
                ) : null}
                {text ? (
                  <div
                    className="text-navy text-[18px] leading-[2.4rem] mt-[2rem]"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(text),
                    }}
                  />
                ) : null}
              </div>
              {image?.url ? (
                <div className="overflow-hidden max-w-[548px] max-h-[300px] w-[100%] h-[100%] self-center order-2 group-even:md:order-1 mx-auto">
                  <Image
                    width="548"
                    height="300"
                    src={image.url}
                    title={image?.title}
                    alt={image?.alt}
                    style={{ objectFit: 'cover', borderRadius: '20px' }}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ZLayoutBlock;
