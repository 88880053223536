import HeroBlockCTAMarkup from './HeroBlockCTAMarkup';
import HeroBlockGuidesMarkup from './HeroBlockGuidesMarkup';
import HeroBlockNewsletterMarkup from './HeroBlockNewsletterMarkup';

export interface ScrollToCTA {
  text: string;
  scroll_to_id: string;
}

export interface HeroBlockProps extends Blog.BlockProps {
  title: string;
  subtitle: string;
  text: string;
  image: Blog.ACFImage;
  version?: string;
  theme?: string;
  cta?: ScrollToCTA;
  newsletter_fields?: Blog.NewsletterFormProps;
}

const HeroBlock = (props: HeroBlockProps) => {
  if (props.version === 'cta') {
    return <HeroBlockCTAMarkup {...props} />;
  }

  if (props.version === 'newsletter') {
    return <HeroBlockNewsletterMarkup {...props} />;
  }

  return <HeroBlockGuidesMarkup {...props} />;
};

export default HeroBlock;
