import { useTranslation } from 'next-i18next';

interface ContentAssetPillProps {
  contentAsset: Blog.ContentAsset;
  extraClasses?: string;
}

const resourceStyles = 'bg-[#00B7FF] text-navy';

const ASSET_COLORS = {
  article: 'bg-green text-navy',
  news: 'bg-purple text-white',
  guide: resourceStyles,
  video: 'bg-[#FFDF00] text-navy',
  ebook: resourceStyles,
  industryreport: resourceStyles,
  deepdive: resourceStyles,
  playbook: resourceStyles,
  resource: resourceStyles,
};

const formatContentAsset = (contentAsset: string) => {
  if (!contentAsset) return null;
  let formattedContentAsset = contentAsset.toLowerCase().replace(' ', '');
  if (formattedContentAsset.endsWith('s') && formattedContentAsset !== 'news') {
    formattedContentAsset = formattedContentAsset.slice(0, -1);
  }
  return formattedContentAsset;
};

const ContentAssetPill = ({
  contentAsset,
  extraClasses,
}: ContentAssetPillProps) => {
  if (!contentAsset) return null;
  const { t } = useTranslation('common');
  const formattedContentAsset = formatContentAsset(contentAsset);

  return (
    <span
      data-testid="content-asset-pill"
      className={`w-max rounded-[2px] text-[10px] leading-[8px] font-bold px-[8px] py-[6px] no-underline uppercase ${ASSET_COLORS[formattedContentAsset]} ${extraClasses}`}
    >
      {t('contentAsset.' + formattedContentAsset)}
    </span>
  );
};

export default ContentAssetPill;
