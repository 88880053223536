import { CoreBlocks } from '@faustwp/blocks';

const containsHTML = (str: string) => {
  const htmlRegex = /<[^>]*>/;
  return htmlRegex.test(str);
};

const ImageProxy = (props) => {
  let imgProps = { ...props };

  if (
    props?.attributes?.url?.startsWith(process.env.NEXT_PUBLIC_WORDPRESS_URL) ||
    props?.attributes?.url?.startsWith(process.env.NEXT_PUBLIC_FRONTEND_URL)
  ) {
    // Clone props to avoid mutating the original object
    imgProps = {
      ...props,
      attributes: { ...props.attributes },
    };
  }

  if (imgProps.attributes && !imgProps.attributes.src) {
    // Create a new attributes object to avoid mutating the original object
    imgProps = {
      ...imgProps,
      attributes: {
        ...imgProps.attributes,
        src: imgProps?.attributes?.url,
      },
    };
  }

  if (
    imgProps?.attributes?.caption &&
    containsHTML(imgProps.attributes.caption)
  ) {
    // Remove html tags and leave text only by creating a new attributes object
    imgProps = {
      ...imgProps,
      attributes: {
        ...imgProps.attributes,
        caption: imgProps.attributes.caption.replace(/<[^>]*>/g, ''),
      },
    };
  }

  return <div className="mb-[32px]">{CoreBlocks.CoreImage(imgProps)}</div>;
};

ImageProxy.displayName = CoreBlocks.CoreImage.displayName;
ImageProxy.fragments = CoreBlocks.CoreImage.fragments;

export default ImageProxy;
