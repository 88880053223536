import Image from 'next/image';
import { sanitize } from 'isomorphic-dompurify';

import { themePadding } from 'styles/shared/defaults';

import NewsletterForm from '../Newsletter/NewsletterForm';

import { HeroBlockProps } from './HeroBlock';

const HeroBlockNewsletterMarkup = (props: HeroBlockProps) => {
  const {
    title,
    text,
    theme,
    image,
    newsletter_fields,
    version,
    anchor,
    className,
  } = props;
  const buttonBgColor = () => {
    const colorThemes = {
      white: 'White',
      navy: 'Navy',
      green: 'Green',
      violet: 'Blue',
    };

    return colorThemes[theme];
  };

  const newsletterProps = {
    heading: '',
    text: '',
    button_text: newsletter_fields?.button_text,
    redirect_url: newsletter_fields?.redirect_url,
    color_theme: buttonBgColor(),
    is_hero_newsletter: true,
    file: newsletter_fields?.file,
    legal_terms: newsletter_fields?.legal_terms,
  };
  const textColor =
    theme === 'navy' || theme === 'violet' ? 'text-white' : 'text-navy';

  return (
    <div
      id={anchor}
      className={`${className ?? ''} hero-block-component bg-${theme} py-[6rem] ${themePadding.pageXPadding} lg:px-[12.7rem]`}
    >
      <div className="mx-auto max-w-page grid grid-cols-1 md:grid-cols-[1fr_305px] lg:grid-cols-[1fr_340px] xl:grid-cols-[1fr_547px] gap-[3rem] lg:gap-[5.8rem] xl:gap-[6.8rem] items-center">
        <div className={`${textColor} order-2 md:order-1 [&>a]:text-navy`}>
          {title ? (
            <h1
              className={`${textColor} text-[3.6rem] sm:text-[4.4rem] md:text-[3.6rem] xl:text-[4.8rem] leading-[1.2] md:leading-[1] lg:leading-[1.3] xl:leading-[1.17]`}
            >
              {title}
            </h1>
          ) : null}
          {text ? (
            <div
              className={`${textColor} text-[1.6rem] sm:text-[1.5rem] leading-[1.35] mt-[1.2rem]`}
              dangerouslySetInnerHTML={{
                __html: sanitize(text),
              }}
            />
          ) : null}
          {version === 'newsletter' ? (
            <div className="mt-[4rem]">
              <NewsletterForm {...newsletterProps} />
            </div>
          ) : null}
        </div>
        {image?.url ? (
          <div className="mx-auto max-w-[547px] max-h-[442px] order-1 md:order-2">
            <Image
              width="547"
              height="422"
              src={image.url}
              title={image?.title}
              alt={image?.alt}
              objectFit="contain"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HeroBlockNewsletterMarkup;
