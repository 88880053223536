import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { TrackableEvents } from '@skillshare/ui-components/shared/events';
import { useEventTracker } from '@skillshare/ui-components/shared/hooks/use-event-tracker';

import { footerMenus, footerUtilityMenus } from 'components/Abstracts/Defaults';
import Container from 'components/Layout/Container';
import FooterSocial from 'components/Footer/FooterSocial';
import FooterLanguageSelector from 'components/Footer/FooterLanguageSelector';

import google from '../../public/images/google-play.svg';
import apple from '../../public/images/app-store.svg';

interface FooterInterface {
  locale: Blog.LocaleType;
  translations: Blog.TranslationType[];
}

const Footer = ({ locale, translations }: FooterInterface) => {
  const { t } = useTranslation('common');
  const year = new Date().getFullYear();
  const { trackEvent } = useEventTracker();

  function trackLinkClick(event: React.MouseEvent) {
    if (!(event?.target instanceof HTMLAnchorElement)) {
      return;
    }

    trackEvent(TrackableEvents.ClickedFooterLink, {
      schema: 'footer/clicked-footer-link.v1.0.0.schema.json',
      link_tracking_id: event.target?.dataset?.trackingId || 'unknown',
      link_href: event.target?.href,
      link_text: event.target?.textContent,
    });
  }

  const linkStyle =
    'text-white text-base md:text-[1.4rem] not-italic font-normal leading-[2.6rem] text-left no-underline hover:text-green hover:underline focus:text-green focus:underline';

  return (
    <div
      data-testid="footer"
      className={`bg-navy text-[#dcdee1] py-3 lg:py-6`}
      onClick={trackLinkClick}
    >
      <Container>
        <section className="grid gap-[3.5rem] grid-cols-2 grid-rows-1 height-[auto]! mb-[3.5rem] lg:grid-cols-4 my-[3.5rem]">
          {footerMenus[locale]?.map((cur, ind) => {
            const menu = cur.items.map((item, ind) => {
              return (
                <li key={ind} className="my-[1.5rem] ml-[1rem] md:m-0">
                  <a
                    className={linkStyle}
                    href={item.url}
                    data-tracking-id={item.trackingId}
                  >
                    {item.title}
                  </a>
                </li>
              );
            });
            return (
              <div key={ind}>
                <h4 className="text-[1.8rem] mb-[2.5rem] md:font-bold mb-[1.6rem]">
                  {cur.title}
                </h4>
                <ul>{menu}</ul>
              </div>
            );
          })}
          <div>
            <h4 className="text-[1.8rem] mb-[2.5rem] md:font-bold mb-[1.6rem]">
              {t('footerMobile')}
            </h4>
            <ul>
              <li className="mb-[1.6rem] mr-[.75rem]" key="0">
                <a
                  className={linkStyle}
                  href="https://itunes.apple.com/app/apple-store/id916819843?pt=96247807&ct=site-footer&mt=8"
                  title={t('footerApple')}
                  data-tracking-id="app-store"
                >
                  <Image alt={t('footerApple')} src={apple} />
                </a>
              </li>
              <li className="mb-[1.6rem] mr-[.75rem]" key="1">
                <a
                  className={linkStyle}
                  href="https://play.google.com/store/apps/details?id=com.skillshare.Skillshare&referrer=utm_source%3Dskillshare%26utm_medium%3Dsite-footer"
                  title={t('footerGoogle')}
                  data-tracking-id="google-play"
                >
                  <Image alt={t('footerGoogle')} src={google} />
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className="items-center flex flex-col-reverse text-xs border-t-[1px] border-solid border-green not-italic font-normal leading-[2.2rem] pt-[1.6rem] text-left md:flex-row md:justify-between">
          <div className="items-center flex flex-col md:flex-row md:justify-between">
            <div key="0" className="max-md:mr-0 mr-[5rem] max-md:mt-[2rem]">
              © Skillshare, Inc. {year}
            </div>
            <div key="1">
              <ul className="flex max-md:flex-wrap max-md:gap-[1rem] max-md:justify-center max-md:mt-[1rem]">
                {footerUtilityMenus[locale]?.map((cur, ind) => {
                  return (
                    <li key={ind} className="mr-[.75rem] md:mx-[2rem]">
                      <a
                        className={linkStyle}
                        href={cur.url}
                        data-tracking-id={cur.trackingId}
                        onClick={cur.clickHandler ? cur.clickHandler : null}
                      >
                        {cur.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div key="2" className="items-center flex">
            <FooterSocial />
            <FooterLanguageSelector
              translations={translations}
              locale={locale}
            />
          </div>
        </section>
      </Container>
    </div>
  );
};

export default Footer;
