import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import twitter from 'public/images/twitter.svg';
import facebook from 'public/images/facebook.svg';
import linkedin from 'public/images/linkedin.svg';
import pinterest from 'public/images/pinterest.svg';

interface SocialProps {
  sticky?: boolean;
  url: string;
}

const SocialShare = ({ sticky, url }: SocialProps) => {
  const { t } = useTranslation('common');
  //@todo: Replace with canonical OG url
  const encodeUrl = encodeURIComponent(url);

  const share = [
    {
      //@todo: translate these more descriptive social share lines
      title: 'Click here to share on Twitter',
      image: twitter,
      url: `https://twitter.com/intent/tweet?url=${encodeUrl}`,
    },
    {
      title: 'Click here to share on Facebook',
      image: facebook,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeUrl}`,
    },
    {
      title: 'Click here to share on LinkedIn',
      image: linkedin,
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeUrl}`,
    },
    {
      title: 'Click here to share on Pinterest',
      image: pinterest,
      url: `https://www.pinterest.com/pin/create/button/?url=${encodeUrl}`,
    },
  ];

  return (
    <div
      data-testid="social-share"
      className={`items-center flex justify-center ${
        sticky ? `lg:flex-col sticky top-[126px]` : 'flex-wrap'
      }`}
    >
      <label className="text-[1.5rem] font-normal tracking-[1px] uppercase">
        {t('share')}
      </label>
      <ul
        className={`items-center self-stretch flex list-none m-0 p-0 ${
          sticky ? 'lg:flex-col' : ''
        }`}
      >
        {share.map((cur, ind) => {
          return (
            <li
              key={ind}
              className={`items-center flex h-[34px] justify-center mr-[5px] mb-[5px] p-0 ${sticky ? 'w-[52px]' : 'w-[32px]'}`}
            >
              <a
                href={cur.url}
                target="_blank"
                rel="noreferrer"
                className="border-none shadow-none h-auto leading-[1] mb-0 mt-[2px] p-[7px] relative indent-0 top-[-2px] w-auto"
                title={cur.title}
              >
                <Image
                  className="object-contain"
                  height={20}
                  width={20}
                  alt={cur.title}
                  src={cur.image}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SocialShare;
