import { WordPressBlocksViewer } from '@faustwp/blocks';
import { gql } from '@apollo/client';

const MaxWidthBlock = ({ innerBlocks, renderedHtml }) => {
  let backgroundColor = 'inherit';
  const styleRegex = /style="background-color: (#[0-9a-fA-F]{6})"/;

  const match = renderedHtml.match(styleRegex);
  if (match && match[1]) {
    backgroundColor = match[1];
  }

  return renderedHtml ? (
    <div
      className="sk-max-width-block"
      data-testid="max-width-block"
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <WordPressBlocksViewer blocks={innerBlocks ?? []} />
    </div>
  ) : null;
};

MaxWidthBlock.fragments = {
  key: `AcfMaxWidthBlockFragment`,
  entry: gql`
    fragment AcfMaxWidthBlockFragment on AcfMaxWidthBlock {
      attributes {
        className
        data
        lock
        metadata
        mode
        name
      }
      innerBlocks {
        __typename
        renderedHtml
        id: clientId
        parentClientId
      }
    }
  `,
};
MaxWidthBlock.displayName = 'AcfMaxWidthBlock';

export default MaxWidthBlock;
