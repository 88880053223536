import { setConfig } from '@faustwp/core';

import possibleTypes from './possibleTypes.json';
import templates from './wp-templates';

/**
 * @type {import('@faustwp/core').FaustConfig}
 **/
export default setConfig({
  experimentalPlugins: [],
  possibleTypes,
  templates: templates,
});
