import { gql } from '@apollo/client';
import React from 'react';

import Heading from 'components/Typography/Heading';
import { align } from 'styles/shared/defaults';

import { sanitizeId } from '../helpers/sanitizeId';

const tags = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
};

export default function CoreHeading(props) {
  const attributes = props.attributes;

  const level = tags[attributes.level] ?? 'h2';

  const alignClass = align[attributes.textAlign] ?? 'text-left';

  let id: string | null;

  if (!attributes.anchor && attributes.level) {
    id = sanitizeId(attributes.content);
  }

  return (
    <Heading
      html={true}
      id={id}
      level={level}
      style={`${attributes?.cssClassName ? attributes.cssClassName : ''} ${alignClass} ${level === 'h2' ? `scroll-mt-[128px]` : ''} !mb-12`}
      text={attributes.content}
    />
  );
}

CoreHeading.fragments = {
  entry: gql`
    fragment CoreHeadingFragment on CoreHeading {
      attributes {
        align
        anchor
        cssClassName
        content
        level
        textAlign
      }
    }
  `,
  key: `CoreHeadingFragment`,
};
