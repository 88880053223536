import CategoryMenu from 'components/Navigation/CategoryMenu';
import MobileMenuCTA from 'components/Navigation/MobileMenuCTA';

interface MobileNavInterface {
  locale: Blog.LocaleType;
}

const MobileNav = ({ locale }: MobileNavInterface) => {
  return (
    <nav className="shadow-[0_10px_0_rgba(0,0,0,0.1)]">
      <CategoryMenu locale={locale} mobile={true} />
      <MobileMenuCTA />
    </nav>
  );
};

export default MobileNav;
