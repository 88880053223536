import Image from 'next/image';

import { themePadding } from 'styles/shared/defaults';

export interface ResourceGridItemProps {
  title: string;
  subtitle?: string;
  image: Blog.ACFImage;
  link: Blog.ACFLink;
  'link-title'?: string;
}

export interface ResourceGridBlockProps extends Blog.BlockProps {
  title: string;
  'resource-grid': ResourceGridItemProps[];
}

const ResourceGridBlock = (props: ResourceGridBlockProps) => {
  return (
    <section
      id={props?.anchor}
      className={`${props?.className} bg-white pt-[4.8rem]`}
    >
      <div className="sk-resource-grid-block bg-[url('../public/images/resource-grid-squiggle.svg')] bg-cover py-[4.8rem] pb-[9.6rem]">
        <div className={`max-w-page mx-auto  ${themePadding.pageXPadding}`}>
          {props?.title && (
            <div
              className={`font-bold text-[3.6rem] mx-auto mb-[3.2rem] max-w-[48.6rem] lg:max-w-[100%]`}
            >
              {props.title}
            </div>
          )}
          <div
            className="sk-resource-grid-repeater-container grid gap-4 justify-center grid-cols-1
        lg:grid-cols-[minmax(44.6rem,1fr)_minmax(44.6rem,1fr)] mx-auto max-w-[48.6rem] lg:max-w-full auto-rows-[25.3rem]"
          >
            {props['resource-grid']?.map((item, i) => {
              return <ResourceGridCard key={i} item={item} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResourceGridBlock;

const ResourceGridCard = ({ item }) => {
  return (
    <div className="sk-resource-grid-item relative rounded-lg overflow-hidden before:block before:absolute before:w-full before:h-full before:bg-gradient-to-b before:from-transparent before:to-black/50 [&>*]:z-1 [&>*]:relative">
      <Image
        alt={item.image.alt}
        src={item.image.url}
        layout="fill"
        className="object-cover"
      />
      <div className="absolute h-full w-full top-0 left-0 bg-cover flex flex-row max-sm:flex-col max-sm:justify-end max-sm:items-center justify-between items-end p-[20px] text-white">
        <div className="flex w-full justify-between">
          <div>
            <h3 className="text-[28px]">{item.title}</h3>
            {item?.subtitle && <p className="text-[22px]">{item.subtitle}</p>}
          </div>
          <div className="flex items-end ml-[24px]">
            <a
              href={item.link.url}
              target="_blank"
              className="border-2 border-green rounded py-2.5 px-4 font-bold text-green hover:bg-green hover:text-navy block"
              rel="noreferrer"
            >
              {item.link.title ? item.link.title : item['link-title']}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
