const align = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
  start: 'text-start',
  end: 'text-end',
};

const violetTheme = {
  bgColor: 'bg-violet',
  textColor:
    'text-white [&>a]:!text-white [&>a]:!underline [&>a]:hover:!text-green',
  buttonTheme: 'Green',
  teacherLink: 'text-green hover:!text-green',
};

const themes: { [K in Blog.ThemeColorOptions]: Blog.ThemeColors } = {
  Green: {
    bgColor: 'bg-green',
    textColor:
      'text-navy [&a]:!text-navy [&>a]:!underline [&>a]:hover:!text-white',
    buttonTheme: 'Navy',
    teacherLink: 'text-violet hover!:text-violet',
  },
  Blue: violetTheme,
  Violet: violetTheme,
  Navy: {
    bgColor: 'bg-navy',
    textColor:
      'text-white [&>a]:!text-white [&>a]:!underline [&>a]:hover:!text-green',
    buttonTheme: 'Green',
    teacherLink: 'text-green hover:!text-green',
  },
};

const defaultTheme: Blog.ThemeColors = {
  bgColor: 'bg-green',
  textColor: 'text-navy',
  buttonTheme: 'Green',
  teacherLink: 'text-violet',
};

const themePadding = {
  pageXPadding: 'px-[16px] md:px-[32px] lg:px-[64px]',
};

export { align, themes, defaultTheme, themePadding };
