import { gql } from '@apollo/client';
import React from 'react';

import Paragraph from 'components/Typography/Paragraph';
import { align } from 'styles/shared/defaults';

export default function CoreParagraph(props) {
  const attributes = props.attributes;

  const alignClass = align[attributes.align] ?? 'text-left';

  return (
    <Paragraph
      style={`${attributes?.cssClassName ? attributes?.cssClassName : ''} ${alignClass}`}
      html={true}
      text={attributes.content}
    />
  );
}

CoreParagraph.fragments = {
  entry: gql`
    fragment CoreParagraphFragment on CoreParagraph {
      attributes {
        align
        content
        cssClassName
      }
    }
  `,
  key: `CoreParagraphFragment`,
};
