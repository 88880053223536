import Link from 'next/link';
import Image from 'next/image';

import check from 'public/images/current_language.svg';
import { getFormattedUrl } from 'libs/getFormattedUrl';
const langTitles = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  pt: 'Português',
};
interface LanguageDrawerInterface {
  bottom?: boolean;
  translations: Blog.TranslationType[];
}

const LanguageDrawer = ({ bottom, translations }: LanguageDrawerInterface) => {
  const linkClasses =
    'items-center text-navy flex text-base py-[1rem] px-[3.2rem] relative no-underline w-full hover:bg-canvas hover:text-navy hover:no-underline focus:bg-canvas focus:text-navy focus:no-underline';

  return (
    <div
      data-testid="language-drawer"
      className={`bg-white shadow-[0_2px_8px_#b4b8b8] rounded-[8px] p-[12px] absolute right-0 w-[160px] ${
        bottom && 'bottom-[46px]'
      }`}
    >
      <ul>
        {translations &&
          translations.map((cur, ind) => {
            const pathname = getFormattedUrl(cur.pathname, cur.code);
            const useAnchor = cur.code !== 'en' && pathname === '/';

            return (
              <li
                className="p-0 m-0 list-none inline-block w-full cursor-pointer"
                key={ind}
              >
                {cur?.current ? (
                  <div
                    data-testid="ld-current-link"
                    className={`${linkClasses} bg-canvas hover:cursor-not-allowed focus:cursor-not-allowed`}
                  >
                    <span className="ml-[-24px] mr-[8px]">
                      <Image
                        alt=""
                        className="h-[16px] object-contain w-[16px]"
                        src={check}
                      />
                    </span>
                    {langTitles[cur.code]}
                  </div>
                ) : useAnchor ? (
                  <a
                    data-testid={`ld-${cur.code}-link`}
                    className={linkClasses}
                  >
                    {langTitles[cur.code]}
                  </a>
                ) : (
                  <Link
                    locale={cur.code}
                    href={{
                      pathname: getFormattedUrl(cur.pathname, cur.code),
                      ...('query' in cur && cur.query && { query: cur.query }),
                    }}
                    passHref
                  >
                    <a
                      data-testid={`ld-${cur.code}-link`}
                      className={linkClasses}
                    >
                      {langTitles[cur.code]}
                    </a>
                  </Link>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default LanguageDrawer;
