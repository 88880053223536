import PostMeta from 'components/Post/PostMeta';

interface PostContentProps {
  asPreview: boolean;
  author: string;
  coauthors?: Blog.AuthorType[];
  parsedContent: JSX.Element;
  slug: string;
}

const PostContent = ({ coauthors, parsedContent, slug }: PostContentProps) => {
  const content = removeQuickLinks(parsedContent);
  return (
    <div
      data-testid="post-content"
      className="mt-[4rem] lg:grow lg:w-[calc(100%-76px-280px)] xl:w-[calc(100%-76px-380px)] post-content"
    >
      {content}
      <PostMeta url={slug} coauthors={coauthors} />
    </div>
  );
};

export default PostContent;

const removeQuickLinks = (parsedContent: JSX.Element): JSX.Element => {
  for (let i = 0; i < parsedContent.props.blocks.length; i++) {
    if (
      parsedContent.props.blocks[i]['__typename'] === 'CoreColumns' &&
      parsedContent.props.blocks[i]?.innerBlocks?.[0].innerBlocks?.[0] &&
      parsedContent.props.blocks[i].innerBlocks[0].innerBlocks[0][
        '__typename'
      ] === 'CoreHeading' &&
      parsedContent.props.blocks[i].innerBlocks[0].innerBlocks[0].attributes
        .content === 'Quick Links'
    ) {
      parsedContent.props.blocks.splice(i, 1);
    }
  }
  return parsedContent;
};
