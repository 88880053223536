import React from 'react';

const IconCaret = () => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.692891 0.710754C0.302891 1.10075 0.302891 1.73075 0.692891 2.12075L4.57289 6.00075L0.692891 9.88075C0.302891 10.2708 0.302891 10.9008 0.692891 11.2908C1.08289 11.6808 1.71289 11.6808 2.10289 11.2908L6.69289 6.70075C7.08289 6.31075 7.08289 5.68075 6.69289 5.29075L2.10289 0.700754C1.72289 0.320754 1.08289 0.320754 0.692891 0.710754Z"
        fill="#002333"
      />
    </svg>
  );
};

export default IconCaret;
