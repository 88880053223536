import Image from 'next/image';

import instagram from 'public/images/instagram.svg';
import pinterest from 'public/images/pinterest.svg';
import linkedin from 'public/images/linkedin.svg';
import youtube from 'public/images/youtube.svg';
import tiktok from 'public/images/tiktok.svg';

const FooterSocial = () => {
  const socials = [
    {
      image: instagram,
      title: 'Instagram',
      url: 'https://instagram.com/skillshare',
    },
    {
      image: linkedin,
      title: 'LinkedIn',
      url: 'https://www.linkedin.com/company/skillshare-com/',
    },
    {
      image: youtube,
      title: 'YouTube',
      url: 'https://www.youtube.com/@Skillshare-com',
    },
    {
      image: pinterest,
      title: 'Pinterest',
      url: 'https://www.pinterest.com/skillshare/',
    },
    {
      image: tiktok,
      title: 'TikTok',
      url: 'https://tiktok.com/@skillshare',
    },
  ];

  return (
    <div data-testid="footer-social">
      <ul>
        {socials.map((cur, ind) => {
          return (
            <li className="inline-block mr-[.4rem]" key={ind}>
              <a
                href={cur.url}
                className="items-center bg-white rounded-[50%] inline-flex h-[28px] justify-center w-[28px]"
                title={cur.title}
                aria-label={cur.title}
                target="_blank"
                rel="noreferrer"
              >
                <picture className="flex">
                  <Image
                    loading="lazy"
                    height="15"
                    width="15"
                    className="h-[15px] object-contain"
                    alt={cur.title}
                    src={cur.image.src}
                  />
                </picture>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FooterSocial;
