import { Post, Page } from 'gql/graphql';

export const mapPostCard = (
  post: Post | Page
): {
  contentAsset: Blog.ContentAsset;
  thumb: string | null;
  title: string;
  uri: string;
} => {
  return {
    // @ts-expect-error - contentAssetType exists on Post type
    contentAsset: post?.contentAssetType,
    thumb: post.featuredImage?.node?.sourceUrl ?? null,
    title: post.title,
    uri: post.slug || post.uri,
  };
};
