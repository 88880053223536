import { FaustProvider } from '@faustwp/core';
import '../faust.config';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import '../styles/globalStylesheet.css';
import '../styles/blocks.scss';
import 'public/css/fonts.css';
import '../styles/globals.css';
import '../styles/themeDefaults.css';
import '../styles/postContent.css';
import { EventsProvider } from '@skillshare/ui-components/components/providers/events';
import { WordPressBlocksProvider } from '@faustwp/blocks';

import { ThemeProvider } from 'libs/ThemeContext';
import { getEventTrackHandler } from 'shared/events/eventTrackHandler';
import { PostProvider } from 'providers/PostProvider';

import { blocks } from '../wp-blocks';
import { FiltersProvider } from '../providers/FiltersProvider';

const trackEventHandler = getEventTrackHandler({
  endpointUrl: process.env.EVENTS_ENDPOINT_URL,
});

function SkillshareBlogApp({ Component, pageProps, router }: AppProps) {
  return (
    <>
      {process.env.NODE_ENV === 'production' ? (
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
      `}
        </Script>
      ) : null}
      <FaustProvider pageProps={pageProps}>
        <WordPressBlocksProvider
          key={router.asPath}
          config={{ blocks, theme: null }}
        >
          <EventsProvider trackEventHandler={trackEventHandler}>
            <ThemeProvider key={router.asPath}>
              <FiltersProvider>
                <PostProvider>
                  <Component
                    {...pageProps}
                    locale={router.locale}
                    key={router.asPath}
                  />
                </PostProvider>
              </FiltersProvider>
            </ThemeProvider>
          </EventsProvider>
        </WordPressBlocksProvider>
      </FaustProvider>
    </>
  );
}

export default appWithTranslation(SkillshareBlogApp);
