// Page Preview
import { gql } from '@apollo/client';
import Head from 'next/head';
import parse from 'html-react-parser';
import { sanitize } from 'isomorphic-dompurify';

import Body from 'components/Layout/Body';
import TopNav from 'components/Navigation/TopNav';
import PageContent from 'components/Page/PageContent';
import Footer from 'components/Footer/Footer';
import { blocks } from 'wp-blocks';

export default function Component(props) {
  const { loading, data } = props;

  const page = data?.page ?? null;

  const headSEO = page?.seo.fullHead ? parse(page.seo.fullHead) : null;
  const schema = page?.seo.schema.raw;

  return (
    <div data-testid="page" className={'page'}>
      <Head>
        {headSEO}={' '}
        <script
          key="yoast-schema"
          type="application/ld+json"
          className="yoast-schema-graph"
          dangerouslySetInnerHTML={{ __html: sanitize(schema) }}
        />
      </Head>
      <TopNav locale={'en'} preview={false} translations={[]} />
      <Body>
        {loading ? (
          <div className="px-[2rem] py-[4rem]">
            <h2 className="text-center">Loading...</h2>
          </div>
        ) : page ? (
          <PageContent page={page} />
        ) : (
          <div className="px-[2rem] py-[4rem]">
            <h2 className="text-center">An error has occurred.</h2>
          </div>
        )}
      </Body>
      <Footer translations={[]} locale={'en'} />
    </div>
  );
}

Component.query = gql`
${blocks.CoreParagraph.fragments.entry}
${blocks.CoreColumns.fragments.entry}
${blocks.CoreColumn.fragments.entry}
${blocks.CoreCode.fragments.entry}
${blocks.CoreButtons.fragments.entry}
${blocks.CoreButton.fragments.entry}
${blocks.CoreQuote.fragments.entry}
${blocks.ImageProxy.fragments.entry}
${blocks.CoreSeparator.fragments.entry}
${blocks.CoreList.fragments.entry}
${blocks.CoreHeading.fragments.entry}
${blocks.CoreGroupBlock.fragments.entry}
${blocks.MaxWidthBlock.fragments.entry}
${blocks.CoreEmbedBlockProxy.fragments.entry}
query PagePreview($databaseId: ID!, $asPreview: Boolean = false) {
  page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
    contentAssetType
    gatedContentTracking {
      trackAsGatedContent
    }
    locale {
      locale
    }
    seo {
      fullHead
      schema {
        raw
      }
    }
    translations {
      href
      locale
    }
    editorBlocks(flat: true) {
      __typename
      renderedHtml
      id: clientId
      parentClientId
      ...${blocks.CoreParagraph.fragments.key}
      ...${blocks.CoreColumns.fragments.key}
      ...${blocks.CoreColumn.fragments.key}
      ...${blocks.CoreCode.fragments.key}
      ...${blocks.CoreButtons.fragments.key}
      ...${blocks.CoreButton.fragments.key}
      ...${blocks.CoreQuote.fragments.key}
      ...${blocks.ImageProxy.fragments.key}
      ...${blocks.CoreSeparator.fragments.key}
      ...${blocks.CoreList.fragments.key}
      ...${blocks.CoreHeading.fragments.key}
      ...${blocks.CoreGroupBlock.fragments.key}
      ...${blocks.MaxWidthBlock.fragments.key}
      ...${blocks.CoreEmbedBlockProxy.fragments.key}
    }
  }
}
`;

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};
