import Navigation from 'components/Navigation/Navigation';

interface TopNavInterface {
  preview?: boolean;
  locale: Blog.LocaleType;
  translations: Blog.TranslationType[];
}

const TopNav = (props: TopNavInterface) => {
  return (
    <div data-testid="top-nav" className="fixed w-full z-[1000]">
      <Navigation {...props} />
    </div>
  );
};

export default TopNav;
