import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import info from 'public/images/info.svg';

import CardPill from './CardPill';

export interface AuthorshipCardProps {
  author?: Blog.AuthorType;
}

const AuthorshipCard = ({ author }: AuthorshipCardProps) => {
  const { t } = useTranslation('common');

  const titles = {
    author: t('authorTitle'),
    coauthor: t('authorTitle'),
    contributor: t('contributorTitle'),
    reviewer: t('reviewerTitle'),
  };

  return (
    <div
      data-testid="authorship-card"
      className="authorship-card bg-white rounded-theme p-8 w-full shadow-sm md:max-w-[380px]"
    >
      <div className="mb-4 flex items-center">
        <span className="uppercase text-[10px] font-bold">
          {titles[author.type]}
        </span>
        {author.type !== 'author' && author.type !== 'coauthor' && (
          <span className="inline-block ml-1">
            {<AuthorshipToolTip type={author.type} />}
          </span>
        )}
      </div>
      <div className="flex">
        {author.profilePic && (
          <div data-testid="authorship-profile-image" className="mr-6 xl:mr-10">
            <div className="rounded-full overflow-hidden h-[96px] w-[96px] xl:w-[120px] xl:h-[120px] relative">
              <Image
                objectFit="cover"
                src={author.profilePic}
                alt={author.displayName}
                layout="fill"
              />
            </div>
            {/* @ts-expect-error teacherType definitely exists */}
            {author.teacherType && author.teacherType !== 'None' && (
              <div className="mt-[16px] text-center">
                {author.type !== 'author' && author.type !== 'coauthor' && (
                  /* @ts-expect-error teacherType definitely exists */
                  <CardPill theme="Green" text={author?.teacherType} />
                )}
              </div>
            )}
          </div>
        )}
        <div
          className={!author.teacherUrl ? 'flex flex-col justify-center' : ''}
        >
          <h3 className="!text-base xl:!text-lg !mb-2 xl:!mb-4 !leading-[1.2]">
            {author.displayName}
          </h3>
          {author.bio && (
            <p
              className="!text-xs xl:!text-base !leading-[1.3] !mb-8"
              dangerouslySetInnerHTML={{ __html: author.bio }}
            />
          )}
          {author.teacherUrl && (
            <a
              data-testid="authorship-card-teacher-url"
              className="!text-xxs xl:!text-xs font-bold mt-8"
              href={author.teacherUrl}
              target="_blank"
              rel="noreferrer"
            >
              {t('viewSkillshareProfile')}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorshipCard;

interface AuthorshipToolTipProps {
  type: Blog.AuthorType['type'];
}

const AuthorshipToolTip = ({ type }: AuthorshipToolTipProps) => {
  const { t } = useTranslation('common');
  return (
    <span data-testid="tooltip" className="tooltip-icon relative">
      <i className="cursor-pointer flex">
        <Image height={14} width={14} src={info} alt="tooltip" />
      </i>
      <span className="tooltip-text text-xs hidden absolute top-0 left-12 z-10 p-4 bg-canvas rounded-md shadow-md w-[120px] xl:w-[160px]">
        {t(`${type}Tooltip`)}
      </span>
    </span>
  );
};
