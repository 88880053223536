import Image from 'next/image';

import { themes, defaultTheme, themePadding } from 'styles/shared/defaults';
import Button from 'components/Buttons/Button';

export interface BigStatsBlockProps extends Blog.BlockProps {
  header: string;
  text: string;
  image: Blog.ACFImage;
  cta: Blog.ACFLink;
  color_theme: Blog.ThemeColorOptions;
}

const BigStatsBlock = (props: BigStatsBlockProps) => {
  const { anchor, className, header, text, image, cta, color_theme } = props;

  const theme = themes[color_theme] || defaultTheme;

  return (
    <section
      data-testid="big-stats-block"
      id={anchor}
      className={`${className ?? ''} ${theme.bgColor || 'bg-navy'} ${theme.textColor || 'text-white'} py-[7rem] ${themePadding.pageXPadding} sm:px-[3.2rem]`}
    >
      <div className="mx-auto max-w-page text-center">
        {header && (
          <h2
            className={`${theme.textColor} text-[3.6rem] leading-[1.2]`}
            // @ts-expect-error textWrap is missing from inline styles
            style={{ textWrap: 'balance' }}
          >
            {header}
          </h2>
        )}
        {text && (
          <p className="mt-[3rem] text-[2.2rem] leading-[1.1]">{text}</p>
        )}
        {image && (
          <div className="mx-auto max-w-[680px] max-h-[370px] mt-[3rem]">
            <Image
              width="680"
              height="370"
              src={image.url}
              title={image.title}
              alt={image.alt}
              objectFit="contain"
            />
          </div>
        )}
        {cta && (
          <div className="mt-12">
            <Button
              href={cta.url}
              size="large"
              target={cta.target}
              theme={theme.buttonTheme}
            >
              {cta.title}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default BigStatsBlock;
