import { useTranslation } from 'next-i18next';

import { sanitizeId } from 'helpers/sanitizeId';

export interface DyanmicTOCProps {
  parsedContent: JSX.Element;
}

const DynamicTOC = ({ parsedContent }: DyanmicTOCProps) => {
  if (parsedContent?.props?.blocks.length === 0) return null;

  const headings = getContentHeadings(parsedContent);

  const { t } = useTranslation('common');

  if (!headings.length) return null;

  return (
    <div
      data-testid="dynamic-toc"
      className="dynamic-toc bg-white rounded-theme p-[24px] shadow-sm lg:mt-[24px] md:max-w-[380px] w-full"
    >
      <h3 className="mb-[12px] text-med">{t('tocTitle')}</h3>
      <ul className="list-disc ml-[20px]">
        {headings.map(({ title, url }, ind) => {
          return title !== 'Quick Links' ? (
            <li className="[&:not(:last-child)]:mb-[8px]" key={url + '-' + ind}>
              <a href={url} dangerouslySetInnerHTML={{ __html: title }} />
            </li>
          ) : null;
        })}
      </ul>
    </div>
  );
};
export default DynamicTOC;

const getContentHeadings = (content: JSX.Element) => {
  const headings = [];

  for (let i = 0; i < content.props.blocks.length; i++) {
    if (
      content.props.blocks[i]['__typename'] === 'CoreHeading' &&
      content.props.blocks[i].attributes.level === 2
    ) {
      if (content.props.blocks[i].attributes.content) {
        if (content.props.blocks[i].attributes.anchor) {
          headings.push({
            title: sanitizeTitle(content.props.blocks[i].attributes.content),
            url: '#' + content.props.blocks[i].attributes.anchor,
          });
        } else {
          headings.push({
            title: sanitizeTitle(content.props.blocks[i].attributes.content),
            url: '#' + sanitizeId(content.props.blocks[i].attributes.content),
          });
        }
      }
    }
  }

  return headings;
};

const sanitizeTitle = (title: string) => {
  if (!title) return '';
  if (title.endsWith(':')) {
    title = title.slice(0, -1);
  }
  const regex = /<a [^>]*>([^<]+)<\/a>/;
  const match = title.match(regex);
  if (match) {
    title = match[1];
  }
  title = title.replace(/^#?\s*\d+[.):]+\s*/, '');
  if (title.startsWith(' ')) {
    title = title.slice(1);
  } else if (title.startsWith('&nbsp;')) title = title.slice(6);
  if (title.indexOf('<strong>') !== -1) {
    title = title.replace('<strong>', '');
    title = title.replace('</strong>', '');
  }
  return title;
};
