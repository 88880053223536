import { useState, useEffect } from 'react';

const CoreFreeformBlock = ({ renderedHtml }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      // TinyMCE doesn't add paragraph tags to the content, so we need to add them manually.
      const contentParagraphed = renderedHtml.replace(/\n/g, '</p><p>');
      setContent('<p>' + contentParagraphed + '</p>');
    }
  }, [renderedHtml]);

  return (
    <div
      className="post-content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

CoreFreeformBlock.displayName = 'CoreFreeform';

export default CoreFreeformBlock;
