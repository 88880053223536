// Post Preview

import { gql } from '@apollo/client';
import Head from 'next/head';
import parse from 'html-react-parser';
import { sanitize } from 'isomorphic-dompurify';

import Body from 'components/Layout/Body';
import TopNav from 'components/Navigation/TopNav';
import Post from 'components/Post/Post';
import Footer from 'components/Footer/Footer';
import { blocks } from 'wp-blocks';

export default function Component(props) {
  const { loading, data } = props;

  const post = data?.post ?? null;

  const headSEO = post?.seo.fullHead ? parse(post.seo.fullHead) : null;
  const schema = post?.seo.schema.raw;

  return (
    <>
      <Head>
        {headSEO}
        <script
          key="yoast-schema"
          type="application/ld+json"
          className="yoast-schema-graph"
          dangerouslySetInnerHTML={{ __html: sanitize(schema) }}
        />
      </Head>
      <TopNav locale={'en'} translations={[]} />
      <Body>
        {loading ? (
          <div className="px-[2rem] py-[4rem]">
            <h2 className="text-center">Loading...</h2>
          </div>
        ) : post ? (
          <Post asPreview={true} locale={'en'} post={post} />
        ) : (
          <div className="px-[2rem] py-[4rem]">
            <h2 className="text-center">An error has occurred.</h2>
          </div>
        )}
      </Body>
      <Footer locale={'en'} translations={[]} />
    </>
  );
}

Component.query = gql`
  ${blocks.CoreParagraph.fragments.entry}
  ${blocks.CoreColumns.fragments.entry}
  ${blocks.CoreColumn.fragments.entry}
  ${blocks.CoreCode.fragments.entry}
  ${blocks.CoreButtons.fragments.entry}
  ${blocks.CoreButton.fragments.entry}
  ${blocks.CoreQuote.fragments.entry}
  ${blocks.ImageProxy.fragments.entry}
  ${blocks.CoreSeparator.fragments.entry}
  ${blocks.CoreList.fragments.entry}
  ${blocks.CoreHeading.fragments.entry}
  ${blocks.CoreGroupBlock.fragments.entry}
  ${blocks.MaxWidthBlock.fragments.entry}
  ${blocks.CoreEmbedBlockProxy.fragments.entry}
  query PostPreview($databaseId: ID!, $asPreview: Boolean = false) {
    post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
        databaseId
        author {
          node {
            description
            name
          }
        }
        authorship {
          bio
          displayName
          type
          profilePic
          teacherUrl
          teacherType
        }
        locale {
          locale
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        excerpt(format: RENDERED)
        date
        modified
        slug
        title
        contentAssetType
        seo {
          fullHead
          schema {
            raw
          }
          readingTime
          opengraphUrl
        }
        
        translations {
          href
          locale
        }
        editorBlocks(flat: true) {
          __typename
          renderedHtml
          id: clientId
          parentClientId
          ...${blocks.CoreParagraph.fragments.key}
          ...${blocks.CoreColumns.fragments.key}
          ...${blocks.CoreColumn.fragments.key}
          ...${blocks.CoreCode.fragments.key}
          ...${blocks.CoreButtons.fragments.key}
          ...${blocks.CoreButton.fragments.key}
          ...${blocks.CoreQuote.fragments.key}
          ...${blocks.ImageProxy.fragments.key}
          ...${blocks.CoreSeparator.fragments.key}
          ...${blocks.CoreList.fragments.key}
          ...${blocks.CoreHeading.fragments.key}
          ...${blocks.CoreGroupBlock.fragments.key}
          ...${blocks.MaxWidthBlock.fragments.key}
          ...${blocks.CoreEmbedBlockProxy.fragments.key}
        }
      }
    }
`;

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};
