import { useState, useEffect } from 'react';
import ReactCompareImage from 'react-compare-image';

const extractImageUrls = (htmlString: string): Array<string> => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');
  const imageTags = htmlDoc.querySelectorAll('img');
  const imageUrls = Array.from(imageTags).map((img) => img.src);

  return imageUrls.slice(0, 2);
};

const ImageCompareBlock = ({ renderedHtml }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (renderedHtml) {
      setImages(extractImageUrls(renderedHtml));
    }
  }, [renderedHtml]);

  return images.length == 2 ? (
    <ReactCompareImage leftImage={images[0]} rightImage={images[1]} />
  ) : null;
};

ImageCompareBlock.displayName = 'JetpackImageCompare';

export default ImageCompareBlock;
