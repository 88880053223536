import { TrackableEvents } from '@skillshare/ui-components/shared/events';
import { useEventTracker } from '@skillshare/ui-components/shared/hooks/use-event-tracker';

import { PostContext } from 'providers/PostProvider';

export const useTrackEventNewsletterSignup = () => {
  const { trackEvent } = useEventTracker();

  return function emit(
    email: string,
    locale: Blog.LocaleType,
    postContext: PostContext,
    path: string,
    utmParams: Record<string, string>
  ) {
    const content_type = postContext.trackAsGatedContent ? 'gated_content' : '';
    const post_type = postContext.contentAssetType ?? '';

    const event = {
      schema: 'memberships/subscribed-to-blog-newsletter.v1.0.2.schema.json',
      email: email,
      translation_target: locale ?? 'en',
      subscription_status_detailed: 'blog_signup',
      content_type,
      post_type,
      post_url: path,
      planType: 'no subscription',
      ...utmParams,
    };
    trackEvent(TrackableEvents.SubscribedToBlogNewsletter, event);
  };
};
