import React, { FunctionComponent } from 'react';

import { themes, defaultTheme, themePadding } from 'styles/shared/defaults';

interface Stat {
  header: string;
  subheader: string;
}

interface SmallStatBlockProps extends Blog.BlockProps {
  color_theme: Blog.ThemeColorOptions;
  stats: Stat[];
}

const SmallStatBlock: FunctionComponent<SmallStatBlockProps> = ({
  anchor,
  className,
  color_theme,
  stats,
}) => {
  console.log(color_theme);
  if (!stats || !stats?.length) return null;
  const { bgColor, textColor } = themes[color_theme] || defaultTheme;

  return (
    <section className={`${bgColor} ${themePadding.pageXPadding}`}>
      <div
        id={anchor}
        data-testid="small-stat-block"
        className={`${className ?? ''} max-w-page mx-auto small-stat-block flex flex-col items-center ${bgColor} ${textColor} py-8 md:flex-row md:min-h-[308px] md:justify-center`}
      >
        {stats.map(({ header, subheader }) => (
          <div
            key={header}
            className={`flex flex-col justify-center items-center min-h-[167px] gap-y-12 md:justify-start md:min-h-[100px] ${stats.length === 2 ? 'md:w-[226px] lg:w-[285px] xl:w-[370px]' : 'md:w-1/3'}`}
          >
            <h2
              className={`text-[6rem] md:text-[5rem] lg:text-[6rem] xl:text-[7rem] ${textColor}`}
            >
              {header}
            </h2>
            <p className="text-center w-[222px] text-[1.4rem] md:text-[1.8rem] md:w-[166px] md:min-h-[66px] lg:text-[2.4rem] lg:w-[225px] xl:w-[269px]">
              {subheader}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SmallStatBlock;
