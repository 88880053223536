import { useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import * as Sentry from '@sentry/browser';

import Button from 'components/Buttons/Button';
import { useTrackEventNewsletterSignup } from 'hooks/useTrackEventNewsletterSignup';
import { themes, defaultTheme } from 'styles/shared/defaults';
import { usePostContext } from 'providers/PostProvider';
import { cleanQueryParam } from 'shared/utils';

export const defaultNewsletterTheme: Omit<
  Blog.ThemeColors,
  'teacherBadge' | 'teacherLink'
> = {
  bgColor: 'bg-green',
  textColor: 'text-navy',
  buttonTheme: 'Green',
};

const NewsletterForm = ({
  heading,
  text,
  button_text,
  redirect_url,
  color_theme,
  is_hero_newsletter = false,
  file,
  legal_terms,
}: Omit<Blog.NewsletterFormProps, 'anchor' | 'className'>) => {
  const { state } = usePostContext();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submitError, setSubmitError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const theme = themes['Green'] || defaultTheme;
  const router = useRouter();

  const { t } = useTranslation('common');

  const { locale, asPath } = useRouter();

  const inputStyle =
    'text-base p-[8px] rounded-[4px] bg-white text-navy w-full';
  const buttonStyle = `min-w-[180px] ${is_hero_newsletter ? 'px-[2rem] py-[1.4rem] text-[1.8rem] leading-[1]' : ''}`;
  const getLinkColor = () => {
    const themes = {
      Blue: '[&>a]:text-green',
      Navy: '[&>a]:text-green',
      White: '[&>a]:text-violet',
      Green: '[&>a]:text-violet',
    };

    return themes[color_theme];
  };
  const linkStyle = is_hero_newsletter ? getLinkColor() : '';

  const trackSignup = useTrackEventNewsletterSignup();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const {
    utm_medium = '',
    utm_source = '',
    utm_campaign = '',
    utm_term = '',
    utm_content = '',
  }: Record<string, string | string[]> = router.query;

  const utmParams = {
    utm_medium: cleanQueryParam(utm_medium),
    utm_source: cleanQueryParam(utm_source),
    utm_campaign: cleanQueryParam(utm_campaign),
    utm_term: cleanQueryParam(utm_term),
    utm_content: cleanQueryParam(utm_content),
  };

  const onSubmitHandler = async (customLink: string | null) => {
    if (!validateEmail(email)) {
      return setEmailError(true);
    }
    try {
      trackSignup(
        email,
        (locale as Blog.LocaleType) ?? 'en',
        state,
        asPath,
        utmParams
      );
      setHasSubmitted(true);
      if (customLink) {
        return window.open(customLink, '_blank');
      }
    } catch (e) {
      setSubmitError(true);
      Sentry.captureMessage(
        `Error when user attempted to submit newsletter signup. Error: ${e?.message}.`,
        'info'
      );
    }
    return;
  };

  const newsletterSucessOrErrorMarkup = () => {
    if (is_hero_newsletter) {
      return (
        <div className="w-full p-[2.2rem_2rem] bg-green rounded-[1.6rem]">
          {!submitError && (
            <h2 className={`!mb-0 text-[2.6rem] text-navy`}>
              {t('heroNewsletterTitle')}
            </h2>
          )}
          <p className={`!mb-0 text-navy mt-[1.2rem]`}>
            {!submitError ? t('heroNewsletterSuccess') : t('newsletterError')}
          </p>
          {!submitError && (
            <p
              className={`text-navy text-left mt-[1.4rem] [&>a]:text-navy [&>a]:font-bold [&>a]:hover:underline`}
              dangerouslySetInnerHTML={{
                __html: t('heroNewsletterSuccessLink', {
                  file_url: file?.url,
                }),
              }}
            />
          )}
        </div>
      );
    }

    return (
      <div className="w-full p-[64px] text-center">
        <h2 className={`!mb-0 ${theme.textColor}`}>
          {!submitError ? t('newsletterSuccess') : t('newsletterError')}
        </h2>
      </div>
    );
  };

  return (
    <>
      {!hasSubmitted && !submitError ? (
        <>
          <h3 className="!mb-[16px]">{heading}</h3>
          {text && (
            <p
              className={`!leading-[1.2] !mb-[32px] !text-[18px]`}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          <div className="mb-[24px]">
            <div className="flex flex-col md:flex-row w-full mb-[8px]">
              <span className="md:flex-1 md:mr-[8px] mb-[12px] md:mb-0">
                <input
                  className={`${inputStyle} h-full ${is_hero_newsletter ? 'border-[1px] border-gray-200' : ''}`}
                  id="email"
                  type="email"
                  placeholder={`${t('email')}*`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </span>
              <Button
                onClick={() => {
                  onSubmitHandler(redirect_url ? redirect_url : null);
                }}
                style={buttonStyle}
                theme={
                  is_hero_newsletter && color_theme !== 'Green'
                    ? 'Green'
                    : 'Navy'
                }
              >
                {button_text}
              </Button>
            </div>
            {emailError && (
              <p className="!text-xs !mb-[8px] !leading-none mt-[4px]">
                {t('validateEmail')}
              </p>
            )}
          </div>
          {legal_terms ? (
            <p
              className={`!text-xs !mb-0 !leading-none ${linkStyle}`}
              dangerouslySetInnerHTML={{ __html: legal_terms }}
            />
          ) : (
            <p
              className={`!text-xs !mb-0 !leading-none ${linkStyle}`}
              dangerouslySetInnerHTML={{
                __html: t('newsletterTOS', {
                  terms: t('newsletterTerms'),
                  privacy: t('newsletterPrivacy'),
                }),
              }}
            />
          )}
        </>
      ) : (
        newsletterSucessOrErrorMarkup()
      )}
    </>
  );
};

export default NewsletterForm;
