import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import greenStar from 'public/images/star.svg';
import navyStar from 'public/images/star-navy.svg';

interface CardPillProps {
  size: 'small' | 'large';
  text: Blog.TeacherType;
  theme: Blog.ThemeColorOptions;
}

const themeColors: { [K in Blog.ThemeColorOptions]: string } = {
  Green: 'bg-navy text-green',
  Blue: 'bg-navy text-green',
  Navy: 'bg-green text-navy',
  Violet: 'bg-green text-navy',
};

const CardPill = ({ size = 'small', text, theme }: CardPillProps) => {
  const { t } = useTranslation();

  const teacherStrings = {
    Teacher: t('teacher'),
    'Top Teacher': t('topTeacher'),
  };
  const pillTheme = themeColors[theme] ?? themeColors.Green;
  return (
    <span
      data-testid="card-pill"
      className={`${pillTheme} inline-flex items-center font-bold  ${size === 'small' ? 'text-xxs py-[2px] px-[4px]' : 'text-[1.4rem] py-[6px] px-[10px]'} rounded-[4px]`}
    >
      {text === 'Top Teacher' && (
        <div data-testid="card-pill-star" className="mr-[4px] flex">
          <Image
            src={theme === 'Navy' ? navyStar : greenStar}
            height={10}
            width={10}
            alt={''}
          />
        </div>
      )}
      {teacherStrings?.[text] ?? text}
    </span>
  );
};

export default CardPill;
