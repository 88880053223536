import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import CategoryMenu from 'components/Navigation/CategoryMenu';
import IconButton from 'components/Buttons/IconButton';
import Button from 'components/Buttons/Button';
import LanguageDrawer from 'components/Localization/LanguageDrawer';
import MobileNav from 'components/Navigation/MobileNav';
import { useTheme } from 'libs/ThemeContext';
import logo from 'public/images/logo.svg';
import globeIcon from 'public/images/language_selector.svg';
import burger from 'public/images/burger.svg';
import close from 'public/images/close.svg';
import { getFormattedUrl } from 'libs/getFormattedUrl';
import { useTrackEventClickedBlogJoinCTA } from 'hooks/useTrackEventClickedBlogJoinCTA';

import Container from '../Layout/Container';
import useScroll from '../../hooks/useScroll';

interface NavigationInterface {
  preview?: boolean;
  locale: Blog.LocaleType;
  translations: Blog.TranslationType[];
}

const Navigation = ({ preview, locale, translations }: NavigationInterface) => {
  const isPreview = preview || false;
  const ctx = useTheme();
  const { t } = useTranslation('common');
  const onClickJoinCTA = useTrackEventClickedBlogJoinCTA();
  const isScrolled = useScroll();

  return (
    <>
      <nav
        className={`bg-white h-[80px] w-full flex items-center ${isScrolled ? 'shadow-[0_10px_0_rgba(0,0,0,0.1)]' : ''}`}
        aria-label={t('menuMain')}
      >
        <Container additionalCssClasses="flex justify-between items-center flex mx-auto max-w-theme">
          <div className="flex items-center justify-between justify-self-start lg:w-[460px] xl:w-[540px] theme:w-[554px]">
            <Link
              href={{
                pathname: getFormattedUrl('/', locale),
              }}
              passHref
            >
              <a
                aria-label={t('menuLogo')}
                className="cursor-pointer flex-shrink mr-[6%]"
              >
                <Image
                  priority={true}
                  src={logo}
                  alt={t('menuBlog')}
                  width={104}
                  height={52}
                />
              </a>
            </Link>
            <CategoryMenu locale={locale} mobile={false} />
          </div>
          <div className="flex flex-shrink items-center justify-self-end gap-[25px] lg:gap-[10px]">
            {!isPreview ? (
              <div className="relative">
                <IconButton
                  click={() => {
                    return ctx.dispatch({ type: 'TOGGLE_TOP_LANG' });
                  }}
                  src={globeIcon}
                  alt={t('language')}
                  ariaLabel={t('language')}
                  priority={true}
                  testId="top-lang-selector"
                />
                {ctx?.state?.topLangIsShown && (
                  <LanguageDrawer translations={translations} bottom={false} />
                )}
              </div>
            ) : null}

            <div className="hidden lg:block">
              <Button
                href="https://www.skillshare.com/membership/checkout?coupon=blog1month&via=blog-nav"
                external={true}
                onClick={onClickJoinCTA}
                style="text-[12px] py-[7px] px-[13px] rounded-[4px] text-nowrap min-w-[156px]"
              >
                {t('joinCTA')}
              </Button>
            </div>
            <div className="lg:hidden">
              <IconButton
                click={() => {
                  return ctx.dispatch({ type: 'TOGGLE_MENU' });
                }}
                src={ctx?.state?.menuIsShown ? close : burger}
                ariaLabel={t('menuMain')}
                alt={t('menuMain')}
                priority={true}
                testId="mobile-menu-button"
              />
            </div>
          </div>
        </Container>
      </nav>
      {ctx?.state?.menuIsShown && <MobileNav locale={locale} />}
    </>
  );
};

export default Navigation;
