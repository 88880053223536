import { eventsDebugLog } from '@skillshare/node-core/events/debug';
import { TrackableEvents } from '@skillshare/ui-components/shared/events';
import { trackEvent } from '@skillshare/ui-components/shared/helpers/track-event';

import { getAuthContext } from '../auth';

import { EventTrackContext } from './context';

const log = eventsDebugLog.extend('handler');

/**
 * Contains props for event tracking.
 *
 * @typedef {object} EventTrackProps
 */
export type EventTrackProps = Record<string, string | string[]>;

/**
 * A handler that can be called to track events.
 *
 * @typedef {Function} EventTrackHandler
 */
export type EventTrackHandler = (event: TrackableEvents, props: EventTrackProps) => Promise<void>;

/**
 * Returns a handler that can be called to track events.
 *
 * @param {EventTrackContext} ctx The handler to be called when events are fired.
 */
export function getEventTrackHandler({ endpointUrl }: EventTrackContext): EventTrackHandler {
    return async (event: TrackableEvents, props: EventTrackProps) => {
        const isServerSide = typeof window === 'undefined';

        if (isServerSide) {
            log('tracking %o event skipped on the server side', event);
            return;
        }

        const { device_session_id } = getAuthContext();

        log('tracking %o event to %o with props %o', event, endpointUrl, props);

        try {
            trackEvent(device_session_id, {
                action: event,
                other: props,
            });
        } catch (error) {
            log('tracking %o event failed with error %o', event, error.toString());
            throw error;
        }

        log(`tracking %o event to %o succeeded`, event, endpointUrl);
    };
}
