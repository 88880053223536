import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import LanguageDrawer from 'components/Localization/LanguageDrawer';
import { useTheme } from 'libs/ThemeContext';
import globe from 'public/images/language_selector_white.svg';
import arrow from 'public/images/chev_down.svg';

const langLabels = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  pt: 'Português',
};

interface FooterLanguageSelectorInterface {
  locale: Blog.LocaleType;
  translations: Blog.TranslationType[];
}

const FooterLanguageSelector = ({
  locale,
  translations,
}: FooterLanguageSelectorInterface) => {
  const { t } = useTranslation();

  const ctx = useTheme();

  const iconStyle = 'h-[20px] object-contain w-[20px]';
  return (
    <div data-testid="footer-language-selector" className="relative">
      {ctx?.state?.footerLangIsShown && (
        <LanguageDrawer translations={translations} bottom={true} />
      )}
      <button
        className="items-center border-[1px] border-solid border-white text-white cursor-pointer flex justify-between ml-[16px] p-[8px] rounded-[4px]"
        aria-label="language"
        onClick={() => {
          ctx.dispatch({ type: 'TOGGLE_FOOTER_LANG' });
        }}
      >
        <span className="items-center flex mr-[32px]">
          <span className="mr-[8px]">
            <Image alt={t('language')} className={iconStyle} src={globe} />
          </span>
          {langLabels[locale]}
        </span>
        <Image alt="" className={iconStyle} src={arrow} />
      </button>
    </div>
  );
};

export default FooterLanguageSelector;
