const Paragraph: React.FC<Blog.ParagraphProps> = ({
  color = 'text-navy',
  html = false,
  style = '',
  text,
}): JSX.Element => {
  const styles = `${color} leading-[3.9rem] mb-[2.5rem] text-[1.9rem] md:text-[2.2rem] ${style}`;
  return (
    <>
      {html ? (
        <p className={`${styles}`} dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <p className={`${styles}`}>{text}</p>
      )}
    </>
  );
};

export default Paragraph;
