import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

export interface ButtonInterface {
  size?: 'normal' | 'large';
  href?: string;
  style?: string;
  children: React.ReactNode;
  external?: boolean;
  onClick?: () => void;
  testId?: string;
  theme?: Omit<Blog.ThemeColorOptions, 'Blue'>;
  target?: string;
}

export const buttonColors = {
  Green:
    'bg-green text-navy visited:bg-green visited:text-navy active:bg-green active:text-navy hover:!text-navy',
  Navy: 'bg-navy text-white visited:bg-navy visited:text-white active:bg-navy active:text-white hover:!text-green',
  Black:
    'bg-black text-green visited:bg-black visited:text-green active:bg-black active:text-green hover:!text-white',
  Transparent:
    'bg-transparent border-navy border-2 text-navy visited:bg-transparent visited:text-navy active:bg-navy active:text-white hover:!bg-navy hover:!text-white',
};

const Button = ({
  size = 'normal',
  href,
  style,
  children,
  external,
  onClick = () => {},
  testId,
  target = '',
  theme = 'Green',
}: ButtonInterface) => {
  const classes = twMerge(
    'btn cursor-pointer text-center py-[.8rem] px-[1.2rem] inline-block rounded-[4px] no-underline font-bold text-xs',
    buttonColors[theme as 'Green' | 'Navy' | 'Transparent'],
    style,
    size === 'large' && 'text-base px-[24px] py-[12px] min-w-[180px]'
  );

  return (
    <>
      {external || href?.startsWith('#') ? (
        <a
          data-testid={testId ?? 'button-external'}
          href={href}
          onClick={onClick}
          className={classes}
          target={target ?? ''}
        >
          {children}
        </a>
      ) : href ? (
        <Link href={href} passHref>
          <a
            data-testid={testId ?? 'button-next'}
            onClick={onClick}
            className={classes}
          >
            {children}
          </a>
        </Link>
      ) : (
        <button
          data-testid={testId ?? 'button-no-link'}
          onClick={onClick}
          className={classes}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
