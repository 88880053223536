import { TrackableEvents } from '@skillshare/ui-components/shared/events';
import { useEventTracker } from '@skillshare/ui-components/shared/hooks/use-event-tracker';

export const useTrackEventClickedBlogJoinCTA = () => {
  const { trackEvent } = useEventTracker();

  return function emit() {
    trackEvent(TrackableEvents.ClickedBlogJoinCTA, {
      'page-title': document.title,
    });
  };
};
