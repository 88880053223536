import { CoreBlocks } from '@faustwp/blocks';

import QuoteBlockProxy from './QuoteBlockProxy';
import CalloutBoxBlockProxy from './CalloutBoxBlockProxy';
import FeaturedPostsBlockProxy from './FeaturedPostsBlockProxy';
import HeroBlockProxy from './HeroBlockProxy';
import ImageProxy from './ImageProxy';
import NewsletterSignupBlockProxy from './NewsletterSignupBlockProxy';
import RecommendedClassesBlockProxy from './RecommendedClassesBlockProxy';
import ResourceGridBlockProxy from './ResourceGridBlockProxy';
import MaxWidthBlock from './MaxWidthBlock';
import { CoreGroupBlock } from './CoreGroupBlock';
import CoreParagraph from './CoreParagraphBlock';
import CoreEmbedBlockProxy from './CoreEmbedBlockProxy';
import CoreFreeformBlock from './CoreFreeformBlock';
import ImageCompareBlock from './ImageCompareBlock';
import CoreHeading from './CoreHeadingBlock';
import TiledGalleryBlock from './TiledGalleryBlock';
import ZLayoutBlockProxy from './ZLayoutBlockProxy';
import SmallStatBlockProxy from './SmallStatBlockProxy';
import BigStatsBlockProxy from './BigStatsBlockProxy';
import InfographicBlockProxy from './InfographicBlockProxy';
import { CoreQuote } from './CoreQuote';

const blocks = {
  CoreParagraph: CoreParagraph,
  CoreColumns: CoreBlocks.CoreColumns,
  CoreColumn: CoreBlocks.CoreColumn,
  CoreCode: CoreBlocks.CoreCode,
  CoreQuote: CoreQuote,
  CoreSeparator: CoreBlocks.CoreSeparator,
  CoreList: CoreBlocks.CoreList,
  CoreButton: CoreBlocks.CoreButton,
  CoreButtons: CoreBlocks.CoreButtons,
  CoreHeading: CoreHeading,
  CalloutBoxBlockProxy,
  CoreEmbedBlockProxy,
  CoreFreeformBlock,
  CoreGroupBlock,
  FeaturedPostsBlockProxy,
  HeroBlockProxy,
  ImageCompareBlock,
  ImageProxy,
  MaxWidthBlock,
  NewsletterSignupBlockProxy,
  RecommendedClassesBlockProxy,
  ResourceGridBlockProxy,
  SmallStatBlockProxy,
  TiledGalleryBlock,
  ZLayoutBlockProxy,
  QuoteBlockProxy,
  BigStatsBlockProxy,
  InfographicBlockProxy,
};

export { blocks };
