/**
 * this is a utility function for passing through a query param that might
 * either be null, a string, or an array
 * it may be a an array if there are multiples of the same query param
 * then we want to pick the first param we see and return it
 */
export const cleanQueryParam = (param: string | string[]) => {
  if (!param) {
    return '';
  }
  return typeof param === 'string' ? param : param[0];
};
