import { Post as PostType } from 'gql/graphql';

export interface PostAuthor {
  profilePic: string;
  displayName: string;
}

const getAuthor = (post: PostType): PostAuthor => {
  const authors =
    Array.isArray(post?.authorship) &&
    post?.authorship?.filter((author) => author?.type === 'author');

  const author = authors?.length ? authors[0] : null;

  return {
    profilePic: author?.profilePic || '/images/post_author.webp',
    displayName: author?.displayName || post?.author?.node?.name,
  };
};

export default getAuthor;
