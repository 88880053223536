import Image from 'next/image';

export interface IconButtonInterface {
  ariaLabel?: string;
  alt?: string;
  src: string;
  priority?: boolean;
  click: () => void;
  testId?: string;
}

const IconButton = ({
  ariaLabel,
  alt,
  src,
  click,
  priority,
  testId,
}: IconButtonInterface) => {
  return (
    <button
      className="items-center border-0 rounded-[50%] cursor-pointer flex h-[40px] justify-center w-[40px] hover:bg-[#efefef] [&>img]:h-[20px] [&>img]:w-[20px]"
      aria-label={ariaLabel}
      onClick={click}
      data-testid={testId ?? 'icon-button'}
    >
      <Image priority={Boolean(priority)} src={src} alt={alt} />
    </button>
  );
};

export default IconButton;
