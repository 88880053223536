import { gql } from '@apollo/client';
import ReactPlayer from 'react-player/lazy';
import { useEffect, useState } from 'react';

import useYoutubeThumbnail from '../hooks/useYoutubeThumbnail';

export interface CoreEmbedBlockProps {
  attributes: {
    url: string;
    caption?: string;
  };
}

const CoreEmbedBlockProxy = ({ attributes }: CoreEmbedBlockProps) => {
  if (!attributes?.url) {
    return null;
  }

  const [videoId, setVideoId] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string>(attributes.url);

  const updateVideoIdAndUrl = (url: string) => {
    if (url.includes('youtube.com')) {
      setVideoId(url.split('v=')[1]);
      setVideoUrl(url.replace('watch?v=', 'embed/'));
    } else {
      setVideoId(null);
      setVideoUrl(url);
    }
  };

  useEffect(() => {
    updateVideoIdAndUrl(attributes.url);
  }, [attributes.url]);

  const thumbnail = useYoutubeThumbnail(videoId);

  const reactPlayerProps = {
    light: thumbnail ? thumbnail : true,
    url: videoUrl,
    width: '100%',
    height: 'auto',
    controls: true,
    style: { aspectRatio: '16/9' },
    playing: true,
    'data-testid': 'core-embed',
  };

  return (
    <>
      <ReactPlayer {...reactPlayerProps} />
      {attributes.caption ? (
        <p className="py-2 text-sm text-center">{attributes.caption}</p>
      ) : null}
    </>
  );
};

CoreEmbedBlockProxy.config = { name: 'CoreEmbed' };
CoreEmbedBlockProxy.displayName = 'CoreEmbed';

CoreEmbedBlockProxy.fragments = {
  key: 'CoreEmbedBlockFragment',
  entry: gql`
    fragment CoreEmbedBlockFragment on CoreEmbed {
      attributes {
        caption
        url
      }
    }
  `,
};

export default CoreEmbedBlockProxy;
