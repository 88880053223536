import React from 'react';
import Link from 'next/link';
import { sanitize } from 'isomorphic-dompurify';

import { Post as PostType } from 'gql/graphql';
import { getFormattedUrl } from 'libs/getFormattedUrl';
import { mapPostCard } from 'helpers/mapPostCard';

import ContentAssetPill from './shared/ContentAssetPill';
import PostCardMeta, { IMAGE_LAYOUT_LEFT } from './shared/PostCardMeta';
import PostCard from './PostCard';
import CardImage from './shared/CardImage';

export interface PostCardHeroProps {
  post: PostType;
  locale: Blog.LocaleType;
  isSingle?: boolean;
  styles?: string;
  bgColor?: string;
}

const PostCardHero = ({
  post,
  locale,
  isSingle = false,
  styles = '',
  bgColor = '',
}: PostCardHeroProps) => {
  if (!post) return null;

  const { contentAsset, thumb, title, uri } = mapPostCard(post);
  const url = getFormattedUrl(uri, locale);

  const heroCardChildren = (
    <div
      data-testid="post-card-hero"
      className={`post-card hidden w-full md:flex md:flex-col lg:min-h-[476px] lg:flex-row bg-white rounded-[8px] shadow-[0_2px_4px_0_rgba(180,184,184,0.5)] overflow-hidden ${styles} ${bgColor}`}
    >
      <div className="post-card__thumb lg:w-1/2">
        <CardImage thumb={thumb} title={title} />
      </div>
      <PostCardContent
        isSingle={isSingle}
        post={post}
        locale={locale}
        contentAsset={contentAsset}
        styles="lg:w-1/2"
      />
    </div>
  );

  return (
    <div className="mt-12">
      <PostCard
        styles="md:hidden"
        post={post}
        locale={locale}
        isSingle={isSingle}
      />
      {isSingle ? (
        heroCardChildren
      ) : (
        <CardHeroWithLink uri={url}>{heroCardChildren}</CardHeroWithLink>
      )}
    </div>
  );
};
export default PostCardHero;

const CardHeroWithLink = ({ children, uri }) => {
  return (
    <Link className="absolute top-0 left-0 h-full w-full" href={uri} passHref>
      <a className="h-full w-full">{children}</a>
    </Link>
  );
};

interface PostCardContentProps {
  contentAsset: Blog.ContentAsset;
  isSingle: boolean;
  locale: Blog.LocaleType;
  post: PostType;
  styles?: string;
}

const PostCardContent = ({
  contentAsset,
  isSingle,
  locale,
  post,
  styles,
}: PostCardContentProps) => {
  const { excerpt, slug, uri, title } = post;
  const postURl = slug || uri;
  const showPill = locale === 'en';

  return (
    <div
      className={`post-card-content align-cc px-[40px] py-[30px] theme:p-[50px] flex flex-col justify-center gap-[12px] ${styles}`}
    >
      {showPill && <ContentAssetPill contentAsset={contentAsset} />}
      {isSingle ? (
        <SinglePostTitle>{title}</SinglePostTitle>
      ) : (
        <NotSinglePostTitle>
          <Link
            href={{
              pathname: getFormattedUrl(postURl, locale),
            }}
            passHref
          >
            <a className={`cursor-pointer block text-navy`}>{title}</a>
          </Link>
        </NotSinglePostTitle>
      )}
      <div
        className="post-card-content__excerpt font-normal  text-[22px] leading-[25.19px] text-navy h-[75.57px] line-clamp-3"
        dangerouslySetInnerHTML={{ __html: sanitize(excerpt) }}
      />
      <PostCardMeta
        post={post}
        locale={locale}
        imageLayout={IMAGE_LAYOUT_LEFT}
      />
    </div>
  );
};

const titleClasses = 'font-bold text-[26px] leading-[29.77px]  line-clamp-3';

const SinglePostTitle = ({ children }) => {
  return (
    <h1 className={`post-card-content__title ${titleClasses}`}>{children}</h1>
  );
};

const NotSinglePostTitle = ({ children }) => {
  return (
    <h2 className={`post-card-content__title ${titleClasses}`}>{children}</h2>
  );
};
