import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

interface FiltersContextType {
  clearAllFilters: () => void;
  selectedTypes: string[];
  setSelectedTypes: Dispatch<SetStateAction<string[]>>;
  selectedTopics: string[];
  setSelectedTopics: Dispatch<SetStateAction<string[]>>;
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  totalSelected: number;
  setTotalSelected: Dispatch<SetStateAction<number>>;
  setFiltersFromQueryParams: () => void;
}

export const FiltersContext: React.Context<FiltersContextType> = createContext({
  clearAllFilters: null,
  selectedTypes: [],
  setSelectedTypes: null,
  selectedTopics: [],
  setSelectedTopics: null,
  showFilters: true,
  setShowFilters: null,
  totalSelected: 0,
  setTotalSelected: null,
  setFiltersFromQueryParams: null,
});

interface FiltersProviderProps {
  children: React.ReactNode;
  initialTypes?: string[];
  initialTopics?: string[];
}

export const FiltersProvider = ({
  children,
  initialTypes = [],
  initialTopics = [],
}: FiltersProviderProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [selectedTypes, setSelectedTypes] = useState(initialTypes);
  const [selectedTopics, setSelectedTopics] = useState(initialTopics);

  const clearAllFilters = () => {
    setSelectedTopics([]);
    setSelectedTypes([]);
  };

  const setFiltersFromQueryParams = () => {
    if (typeof window !== 'undefined') {
      const url = new URLSearchParams(window.location.search);
      if (url.has('topic')) {
        setSelectedTopics(url.getAll('topic').join(',').split(','));
      }

      if (url.has('content')) {
        setSelectedTypes(url.getAll('content').join(',').split(','));
      }
    }
  };

  useEffect(() => {
    setTotalSelected(selectedTopics.length + selectedTypes.length);
  }, [selectedTopics, selectedTypes]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (typeof window !== 'undefined') {
        const url = new URL(window.location.href);
        if (selectedTopics.length) {
          url.searchParams.set('topic', selectedTopics.join(','));
        } else {
          url.searchParams.delete('topic');
        }
        if (selectedTypes.length) {
          url.searchParams.set('content', selectedTypes.join(','));
        } else {
          url.searchParams.delete('content');
        }
      }
    };

    handleRouteChange();
    setTotalSelected(selectedTopics.length + selectedTypes.length);
  }, [selectedTopics, selectedTypes]);

  return (
    <FiltersContext.Provider
      value={{
        clearAllFilters,
        selectedTopics,
        setSelectedTopics,
        selectedTypes,
        setSelectedTypes,
        showFilters,
        setShowFilters,
        totalSelected,
        setTotalSelected,
        setFiltersFromQueryParams,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }
  return context;
};
