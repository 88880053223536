import { Slide } from 'pure-react-carousel';

import Carousel from 'components/Carousel/Carousel';

import AuthorshipCard from './AuthorshipCard';

export interface AuthorshipCardCarouselProps {
  authors: Blog.AuthorType[];
}

const AuthorshipCardCarousel = ({ authors }: AuthorshipCardCarouselProps) => {
  const authorEls = authors.map((author, index) => (
    <Slide className="mx-[4px]" index={index} key={index}>
      <AuthorshipCard author={author} />
    </Slide>
  ));

  return (
    <div
      data-testid="authorship-card-carousel"
      className="authorship-card-carousel mb-[16px] md:mb-0 max-w-[380px] w-full"
    >
      <Carousel items={authorEls} naturalSlideWidth={320} />
    </div>
  );
};

export default AuthorshipCardCarousel;
