import { useState, useEffect } from 'react';

import QuoteBlock from 'components/Blocks/QuoteBlock';

import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';

const QuoteBlockProxy = ({ renderedHtml }) => {
  const [quoteBlockProps, setProps] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      setProps(getObjDataFromRenderedHtml(renderedHtml));
    }
  }, [renderedHtml]);

  return quoteBlockProps ? <QuoteBlock {...quoteBlockProps} /> : null;
};

QuoteBlockProxy.displayName = 'AcfQuoteBlock';

export default QuoteBlockProxy;
