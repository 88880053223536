import newsletterBackground from 'public/images/newsletter_bg.webp';
import NewsletterForm from 'components/Newsletter/NewsletterForm';
import { themes, defaultTheme } from 'styles/shared/defaults';

const NewsletterSignupBlock = (
  props: Blog.NewsletterFormProps
): JSX.Element => {
  const { anchor, className } = props;
  const theme = themes['Green'] || defaultTheme;
  return (
    <div
      id={anchor}
      className={`${className ?? ''} newsletter-signup ${theme.bgColor} ${theme.textColor} flex items-center p-0 m-0 relative rounded-theme min-h-[350px] max-w-theme mx-auto`}
    >
      <div
        style={{ backgroundImage: `url(${newsletterBackground.src})` }}
        className={`newsletter-signup__background absolute`}
      />
      <div className={`px-[64px] py-[48px] mr-auto z-50`}>
        <div className={`md:max-w-[580px]`}>
          <NewsletterForm {...props} />
        </div>
      </div>
    </div>
  );
};

export default NewsletterSignupBlock;
