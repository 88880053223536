import Image from 'next/image';

import { User } from 'gql/graphql';
import Button from 'components/Buttons/Button';
import CardPill from 'components/Cards/CardPill';
import { themes, defaultTheme } from 'styles/shared/defaults';

interface CustomTeacherFields {
  profile_picture: Blog.ACFImage;
  skillshare_teacher_url: string;
  one_on_one_url: string;
  facebook: string;
  instagram: string;
  pinterest: string;
  linkedin: string;
  user_avatar?: string;
}

export interface CalloutBoxBlockProps extends Blog.BlockProps {
  color_theme: Blog.ThemeColorOptions;
  title: string;
  content: string;
  image: Blog.ACFImage | false;
  teacher: (User & CustomTeacherFields) | false;
  button: Blog.ACFLink;
}

const CalloutBoxBlock = ({
  anchor,
  className,
  color_theme,
  title,
  content,
  image,
  teacher,
  button,
}: CalloutBoxBlockProps): JSX.Element => {
  const { bgColor, textColor, buttonTheme, teacherLink } =
    themes[color_theme] || defaultTheme;
  return (
    <div
      id={anchor}
      data-testid="callout-box-block"
      className={`${className ?? ''} flex flex-col xl:flex-row w-full p-[4rem] ${bgColor} max-w-[500px] xl:max-w-page mx-auto rounded-theme`}
    >
      {(image || teacher) && (
        <div
          data-testid="callout-box-block-img-or-teacher"
          className={`flex items-start xl:items-center flex-grow-0 flex-shrink-0 ${image ? 'max-w-[360px]' : 'max-w-[240px]'} mx-auto xl:ml-0 xl:mr-auto ${image ? 'xl:w-[360px]' : 'xl:w-[240px]'} mb-[4rem] xl:mb-0`}
        >
          {image && (
            <div className="rounded-theme overflow-hidden">
              <Image
                height={image.height}
                width={image.width}
                src={image.url}
                alt={image.alt || ''}
              />
            </div>
          )}
          {teacher && (
            <div
              data-testid="callout-box-block-teacher"
              className="text-center w-full"
            >
              {teacher?.profile_picture && (
                <div
                  data-testid="callout-box-block-teacher-img"
                  className="overflow-hidden rounded-full max-w-[180px] max-h-[180px] mb-[24px] mx-auto"
                >
                  <Image
                    height={teacher.profile_picture.height}
                    width={teacher.profile_picture.width}
                    src={teacher.profile_picture.url}
                    alt={teacher.profile_picture.alt}
                  />
                </div>
              )}
              {/* @ts-expect-error type definitely exists on User type */}
              {teacher.type !== 'None' && (
                <div className="mb-[8px]">
                  {/* @ts-expect-error type definitely exists on User type */}
                  <CardPill theme={color_theme} text={teacher.type} />
                </div>
              )}
              <h3 className={`${textColor} !mb-[16px]`}>
                {/* @ts-expect-error display_name definitely exists on User type */}
                {teacher.display_name}
              </h3>
              <p className="!mb-0">
                <a
                  className={`${teacherLink} hover:underline text-base font-bold`}
                  href={teacher.skillshare_teacher_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Skillshare Profile
                </a>
              </p>
            </div>
          )}
        </div>
      )}
      <div
        data-testid="callout-box-block-text-wrapper"
        className={`flex-grow-0 flex-shrink-0 w-full ${image ? 'xl:w-[calc(100%-360px)]' : ''} ${teacher ? 'xl:w-[calc(100%-240px)]' : ''} ${image || teacher ? 'xl:pl-[4rem]' : ''}`}
      >
        <h2
          data-testid="callout-box-block-title"
          className={`${textColor} !mb-[2rem]`}
        >
          {title}
        </h2>
        <p
          data-testid="callout-box-block-content"
          className={`${textColor} !leading-normal`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {button && (
          <Button
            target={button?.target}
            testId="callout-box-block-btn"
            external
            href={button.url}
            theme={buttonTheme}
            style={`!mt-[2rem]`}
          >
            {button.title}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CalloutBoxBlock;
