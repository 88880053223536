import { getContentFromBlocks } from '../../helpers/getContentFromBlocks';
import { Page as PageType } from '../../gql/graphql';

interface PageContentInterface {
  page: PageType;
}

const PageContent = (props: PageContentInterface) => {
  const parsedContent = getContentFromBlocks(props.page);
  return <div className="page-content">{parsedContent}</div>;
};

export default PageContent;
