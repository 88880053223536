import { useState, useEffect } from 'react';

import HeroBlock from '../components/Blocks/HeroBlock';
import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';

const HeroBlockProxy = ({ renderedHtml }) => {
  const [heroProps, setProps] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      setProps(getObjDataFromRenderedHtml(renderedHtml));
    }
  }, [renderedHtml]);

  return heroProps ? <HeroBlock {...heroProps} /> : null;
};

HeroBlockProxy.displayName = 'AcfHeroBlock';

export default HeroBlockProxy;
