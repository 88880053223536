import Link from 'next/link';
import { ReactElement, useEffect, useState } from 'react';

import client from '../../client/apollo-client';
import { MenuQuery } from '../../queries/menu';
import { getFormattedUrl } from '../../libs/getFormattedUrl';

interface CategoryMenuInterface {
  mobile?: boolean;
  locale: Blog.LocaleType;
}

const BLOG_MENU_VARS = {
  id: 'main-blog-navigation',
  idType: 'SLUG',
};

const CategoryMenu = ({ locale, mobile }: CategoryMenuInterface) => {
  if (locale !== 'en') {
    return null;
  }

  const [menu, setMenu] = useState([
    { title: 'Articles', url: '/articles' },
    { title: 'Resources', url: '/resources' },
    { title: 'News', url: '/news' },
    { title: 'Classes', url: 'https://www.skillshare.com/es/browse?via=blog' },
  ]);

  useEffect(() => {
    const fetchMenu = async () => {
      const { data } = await client.query({
        query: MenuQuery,
        variables: BLOG_MENU_VARS,
      });
      const menuItems = data.menu.menuItems.edges.map((edge) => ({
        title: edge.node.label,
        url: edge.node.url,
      }));

      setMenu(menuItems);
    };

    fetchMenu();
  }, []);

  return (
    <div
      className={`${
        !mobile
          ? 'hidden lg:block flex-grow w-[300px]'
          : 'bg-white block' + ' lg:hidden'
      } `}
    >
      <ul className={`${!mobile ? 'flex justify-between' : ''}`}>
        {menu &&
          menu.length > 0 &&
          menu.map((cur, ind) => {
            let url;
            if (cur.url.startsWith('http')) {
              url = cur.url;
              cur['external'] = true;
            } else {
              url = getFormattedUrl(cur.url, locale);
            }
            return (
              <li key={ind}>
                {'external' in cur && cur.external ? (
                  <a href={url}>
                    <MenuLink mobile={mobile} color="text-navy">
                      <>{cur.title}</>
                    </MenuLink>
                  </a>
                ) : (
                  <Link href={url} passHref>
                    <a>
                      <MenuLink mobile={mobile} color="text-navy">
                        <>{cur.title}</>
                      </MenuLink>
                    </a>
                  </Link>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default CategoryMenu;

interface MenuLinkInterface {
  children: ReactElement;
  color: string;
  mobile: boolean;
}

const MenuLink = ({ children, color, mobile }: MenuLinkInterface) => {
  const isOrIsNotMobile = !mobile
    ? 'block font-bold text-[12.2px] leading-[33px] mx-[.8rem] focus:text-hover'
    : 'items-center flex text-[1.4rem] font-bold justify-center py-[2.5rem] hover:bg-green focus:bg-green focus:text-purple';
  return (
    <span
      className={`${color} cursor-pointer uppercase no-underline tracking-[1px] visited:text-navy visited:no-underline active:text-navy active:no-underline hover:text-[#3722D3] ${isOrIsNotMobile}`}
    >
      {children}
    </span>
  );
};
