import { useEffect, useState } from 'react';

import CalloutBoxBlock, {
  CalloutBoxBlockProps,
} from '../components/Blocks/CalloutBoxBlock';
import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';

interface Props {
  renderedHtml: string | null;
}

const CalloutBoxBlockProxy: React.FC<Props> = ({ renderedHtml }) => {
  const [calloutBoxProps, setProps] = useState<CalloutBoxBlockProps | null>(
    null
  );

  useEffect(() => {
    setProps(getObjDataFromRenderedHtml(renderedHtml) as CalloutBoxBlockProps);
  }, [renderedHtml]);

  return calloutBoxProps ? <CalloutBoxBlock {...calloutBoxProps} /> : null;
};

CalloutBoxBlockProxy.displayName = 'AcfCalloutBoxBlock';

export default CalloutBoxBlockProxy;
