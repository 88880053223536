import { gql } from '@apollo/client';

export const MenuQuery = gql`
  query MenuQuery($id: ID!, $idType: MenuNodeIdTypeEnum) {
    menu(id: $id, idType: $idType) {
      menuItems(first: 100) {
        edges {
          node {
            label
            url
          }
        }
      }
    }
  }
`;
