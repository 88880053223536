import SocialShare from 'components/Post/SocialShare';
import AuthorshipCard from 'components/Cards/AuthorshipCard';
import AuthorshipCardCarousel from 'components/Cards/AuthorshipCardCarousel';

interface MetaProps {
  coauthors?: Blog.AuthorType[];
  url: string;
}

const PostMeta = ({ coauthors, url }: MetaProps) => {
  return (
    <div data-testid="post-meta">
      <div className="items-center flex flex-col-reverse justify-between mt-[6rem] mb-[8rem] lg:flex-row">
        <div className="flex justify-between mt-[6rem] md:mt-0 max-w-[490px]">
          {coauthors && coauthors.length > 1 ? (
            <AuthorshipCardCarousel authors={coauthors} />
          ) : (
            coauthors &&
            coauthors.length && <AuthorshipCard author={coauthors[0]} />
          )}
        </div>
        <div className="w-1/2 ml-[16px]">
          <SocialShare url={url} />
        </div>
      </div>
    </div>
  );
};

export default PostMeta;
