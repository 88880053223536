import { useReducer, createContext, useContext } from 'react';

const init = {
  topLangIsShown: false,
  footerLangIsShown: false,
  menuIsShown: false,
  generateTOC: true,
};

const reducer = (state, action) => {
  const newState = { ...state };
  switch (action.type) {
    case 'TOGGLE_TOP_LANG': {
      if (newState.footerLangIsShown) {
        newState.footerLangIsShown = false;
      }
      if (newState.menuIsShown) {
        newState.menuIsShown = false;
      }
      newState.topLangIsShown = !newState.topLangIsShown;
      return newState;
    }
    case 'TOGGLE_FOOTER_LANG': {
      if (newState.menuIsShown) {
        newState.menuIsShown = false;
      }
      if (newState.topLangIsShown) {
        newState.topLangIsShown = false;
      }
      newState.footerLangIsShown = !newState.footerLangIsShown;
      return newState;
    }
    case 'TOGGLE_MENU': {
      if (newState.topLangIsShown) {
        newState.topLangIsShown = false;
      }
      if (newState.footerLangIsShown) {
        newState.footerLangIsShown = false;
      }
      newState.menuIsShown = !newState.menuIsShown;
      return newState;
    }
    case 'GENERATE_TOC': {
      newState.generateTOC = true;
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, init);
  const value = { state, dispatch };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const ctx = useContext(ThemeContext);
  return ctx;
};
