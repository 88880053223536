export function getDataFromRenderedHtml(
  renderedHtml: string,
  startToken: string,
  endToken: string
) {
  if (!renderedHtml) {
    return null;
  }

  const startIndex = renderedHtml.indexOf(startToken);
  const endIndex = renderedHtml.lastIndexOf(endToken) + endToken.length;

  const jsonContent = renderedHtml.substring(startIndex, endIndex).trim();
  return JSON.parse(jsonContent);
}

export function getArrayDataFromRenderedHtml(
  renderedHtml: string
): Array<object> {
  return getDataFromRenderedHtml(renderedHtml, '[{', '}]');
}

export function getObjDataFromRenderedHtml(renderedHtml: string): object {
  return getDataFromRenderedHtml(renderedHtml, '{', '}');
}
