import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { ClassCard } from '@skillshare/ui-components/components/class-card';
import { DefaultThemeProvider } from '@skillshare/ui-components/themes';
import { IntlProvider } from 'react-intl';

import client from 'client/sk-api-client';
import Button from 'components/Buttons/Button';

interface RecommendedClassesBlockProps extends Blog.BlockProps {
  classes: {
    title: string;
    link: Blog.ACFLink;
    classes: Record<string, unknown>;
    errors: unknown[];
  };
}

const RecommendedClassesBlock = (props: RecommendedClassesBlockProps) => {
  if (!props.classes) {
    return <></>;
  }

  const { anchor, className } = props;
  const { title, link, classes, errors } = props.classes;

  if (errors) {
    errors.map((cur) => {
      return Sentry.captureMessage(
        //@ts-expect-error - Message exists on error type
        'Error thrown in RecommendedClassesBlock class data: ' + cur?.message
      );
    });
  }

  const classArr = [];

  Object.entries(classes).forEach((cur) => {
    if (cur[1]) {
      classArr.push(cur[1]);
    }
  });

  return (
    <div id={anchor} className={`${className ?? ''} recommended-classes-block`}>
      {title || link.url ? (
        <div className="md:flex md:align-center md:justify-between mb-[3.2rem]">
          {title ? <h3 className="mb-[1.6rem] md:!mb-0">{title}</h3> : null}
          {link.url ? (
            <Button href={link.url} external={true}>
              {link.title}
            </Button>
          ) : null}
        </div>
      ) : null}
      {classArr.length > 0 ? (
        <div className="overflow-x-scroll flex">
          <div className="inline-grid lg:mx-auto grid-cols-classCards">
            {classArr.map((cur, ind) => {
              return (
                <div key={ind}>
                  <ClassCardComponent client={client} class={cur} />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RecommendedClassesBlock;

const ClassCardComponent = (props) => {
  return (
    <IntlProvider locale="en">
      <ApolloProvider client={props.client}>
        <DefaultThemeProvider>
          <ClassCard
            shouldTrackClassImpression={false}
            shouldOpenInNewTab={true}
            key={props.ind}
            hideSave={true}
            {...props.class}
          />
        </DefaultThemeProvider>
      </ApolloProvider>
    </IntlProvider>
  );
};
