import dayjs from 'dayjs';

import { Post as PostType } from 'gql/graphql';

import getAuthor from '../../../helpers/getAuthor';

import AuthorAvatar from './AuthorAvatar';

export const IMAGE_LAYOUT_TOP = 'top';
export const IMAGE_LAYOUT_LEFT = 'left';

export type ImageLayout = typeof IMAGE_LAYOUT_TOP | typeof IMAGE_LAYOUT_LEFT;

export interface PostCardMetaProps {
  post: PostType;
  locale: Blog.LocaleType;
  imageLayout?: ImageLayout;
}
const PostCardMeta = ({ post, imageLayout, locale }: PostCardMetaProps) => {
  if (!post) return null;

  let published = null;
  const author = getAuthor(post);

  if (post?.date) {
    const format = locale && locale === 'en' ? 'MMM D, YYYY' : 'D MMM YYYY';
    published = dayjs(post?.date).locale(locale).format(format);
  }

  if (imageLayout === IMAGE_LAYOUT_TOP) {
    return (
      <div className={`post-card-meta mt-[-16px]`}>
        <AuthorAvatar author={author} className="ml-[-4px]" />
        <div className="post-card-meta__info flex justify-between my-2.5 font-bold text-[12px] text-charcoal leading-[12px] spacing-[2%] items-center">
          <span className="post-card-meta__info__author">
            {author.displayName}
          </span>
          <span
            data-testid="meta-published"
            className="post-card-meta__info__date text-right align-center"
          >
            {published}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="post-card-meta flex justify-between my-2.5 font-bold text-[15px] text-navy leading-[20px] items-center">
      <div className="flex items-center">
        <AuthorAvatar author={author} className="mr-[4px]" />
        <span className="post-card-meta__info__author">
          {author.displayName}
        </span>
      </div>
      <span className="post-card-meta__info__date text-right align-center">
        {published}
      </span>
    </div>
  );
};

export default PostCardMeta;
