import { themePadding } from 'styles/shared/defaults';

interface ContainerInterface {
  narrow?: boolean;
  children: React.ReactNode;
  additionalCssClasses?: string;
}

const Container = ({
  narrow,
  children,
  additionalCssClasses = '',
}: ContainerInterface) => {
  const narrowClasses =
    'my-[5rem] w-[85%] max-w-[860px] md:my-[6rem] md:w-[60%]';
  return (
    <div
      data-testid="container"
      className={`w-full mx-auto ${themePadding.pageXPadding} max-w-theme ${
        narrow ? narrowClasses : ''
      } ${additionalCssClasses}`}
    >
      {children}
    </div>
  );
};

export default Container;
