import { useState, useEffect } from 'react';

import FeaturedPostsBlock from '../components/Blocks/FeaturedPostsBlock';
import { getArrayDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';

const FeaturedPostsBlockProxy = ({ renderedHtml }) => {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      setPosts(getArrayDataFromRenderedHtml(renderedHtml));
    }
  }, [renderedHtml]);

  return <FeaturedPostsBlock posts={posts} />;
};

FeaturedPostsBlockProxy.displayName = 'AcfFeaturedPostsBlock';

export default FeaturedPostsBlockProxy;
