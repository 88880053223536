import { useState, useEffect } from 'react';

import BigStatsBlock from 'components/Blocks/BigStatsBlock';

import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';

const BigStatsBlockProxy = ({ renderedHtml }) => {
  const [bigStatsProps, setProps] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      setProps(getObjDataFromRenderedHtml(renderedHtml));
    }
  }, [renderedHtml]);

  return bigStatsProps ? <BigStatsBlock {...bigStatsProps} /> : null;
};

BigStatsBlockProxy.displayName = 'AcfBigStatsBlock';

export default BigStatsBlockProxy;
