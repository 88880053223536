import { Slide } from 'pure-react-carousel';
import Image from 'next/image';

import Heading from 'components/Typography/Heading';
import Paragraph from 'components/Typography/Paragraph';
import Carousel from 'components/Carousel/Carousel';
import defaultAuthorImg from 'public/images/post_author_large.svg';
import CardPill from 'components/Cards/CardPill';
import { themePadding } from 'styles/shared/defaults';

interface Quote {
  badge_pill: Blog.BadgePill;
  heading: string;
  image?: string;
  quote: string;
}

interface QuoteBlockProps extends Blog.BlockProps {
  title: string;
  quotes: Quote[];
}

const QuoteBlock = ({ anchor, className, title, quotes }: QuoteBlockProps) => {
  const quoteEls =
    quotes.length > 1 &&
    quotes.map((quote, ind) => (
      <Slide className="mx-2" index={ind} key={ind}>
        <QuoteComponent quote={quote} />
      </Slide>
    ));
  return (
    <section
      id={anchor}
      className={`${className ?? ''} bg-white py-32 ${themePadding.pageXPadding}`}
      data-testid="quote-block"
    >
      {title && <Heading level="h2" style="text-center !mb-16" text={title} />}
      <div className="max-w-[920px] mx-auto">
        {quotes.length > 1 ? (
          <Carousel dotStyle="large" items={quoteEls} naturalSlideWidth={920} />
        ) : (
          <QuoteComponent quote={quotes[0]} />
        )}
      </div>
    </section>
  );
};

export default QuoteBlock;

interface QuoteComponentProps {
  quote: Quote;
}

const QuoteComponent = ({ quote }: QuoteComponentProps) => {
  return (
    <div className="grid gap-32 md:grid-cols-quoteBlock" data-testid="quote">
      <div className="text-center md:text-left md:flex flex-col items-center">
        <Image
          className="rounded-full"
          alt={`Image of ${quote.heading}`}
          src={quote.image ? quote.image : defaultAuthorImg}
          height={260}
          width={260}
        />
        {(quote.badge_pill.badge_text !== 'None' ||
          quote.badge_pill.other_badge_text) && (
          <div className="mt-6">
            <CardPill
              size="large"
              theme="Green"
              text={getBadgeText(quote.badge_pill)}
            />
          </div>
        )}
      </div>
      <div className="md:flex flex-col justify-center">
        <Heading level="h3" style="!mb-4" text={quote.heading} />
        <Paragraph text={quote.quote} />
      </div>
    </div>
  );
};

const getBadgeText = (pill: Blog.BadgePill) => {
  if (pill.badge_text === 'None') {
    return null;
  }
  return pill.badge_text !== 'Other' ? pill.badge_text : pill.other_badge_text;
};
