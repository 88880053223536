import React from 'react';
import { gql } from '@apollo/client';
import {
  getStyles,
  useBlocksTheme,
  WordPressBlocksViewer,
} from '@faustwp/blocks';
import { ContentBlock } from '@faustwp/blocks/dist/cjs/components/WordPressBlocksViewer';

export type CoreGroupFragmentProps = ContentBlock & {
  attributes?: {
    cssClassName?: string;
    align?: string;
    anchor?: string;
    layout?: string;
    isStackedOnMobile?: boolean;
    verticalAlignment?: string;
    borderColor?: string;
    backgroundColor?: string;
    fontSize?: string;
    fontFamily?: string;
    style?: string;
    textColor?: string;
    gradient?: string;
  };
};

export function CoreGroupBlock(props) {
  const theme = useBlocksTheme();
  const style = getStyles(theme, { ...props });

  const { attributes, innerBlocks } = props;

  return (
    <div style={style} className={attributes?.cssClassName + ' core-group'}>
      <WordPressBlocksViewer blocks={innerBlocks ?? []} />
    </div>
  );
}

CoreGroupBlock.fragments = {
  key: `CoreGroupBlockFragment`,
  entry: gql`
    fragment CoreGroupBlockFragment on CoreGroup {
      attributes {
        align
        anchor
        layout
        className
        borderColor
        backgroundColor
        fontSize
        fontFamily
        style
        textColor
        gradient
      }
      innerBlocks {
        __typename
        renderedHtml
        id: clientId
        parentClientId
      }
    }
  `,
};
CoreGroupBlock.config = {
  name: 'CoreGroup',
};
CoreGroupBlock.displayName = 'CoreGroup';
