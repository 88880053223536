import { useState, useEffect } from 'react';

const useYoutubeThumbnail = (videoId: string | null) => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [hasHQImage, setHasHQImage] = useState<boolean>(true);

  useEffect(() => {
    if (!videoId) {
      setThumbnailUrl(null);
      return;
    }

    const checkImage = () => {
      const img = new Image();

      img.onload = () => {
        if (img.naturalWidth === 120 && img.naturalHeight === 90) {
          setHasHQImage(false);
        }
      };

      img.src = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    };

    checkImage();
  }, [videoId]);

  useEffect(() => {
    if (!videoId) {
      setThumbnailUrl(null);
      return;
    }

    if (hasHQImage) {
      setThumbnailUrl(
        `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
      );
    } else {
      setThumbnailUrl(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
    }
  }, [hasHQImage, videoId]);

  return thumbnailUrl;
};

export default useYoutubeThumbnail;
