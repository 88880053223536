import { useState, useEffect } from 'react';

import ZLayoutBlock from '../components/Blocks/ZLayoutBlock';
import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';

const ZLayoutProxy = ({ renderedHtml }) => {
  const [zLayoutProps, setProps] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      setProps(getObjDataFromRenderedHtml(renderedHtml));
    }
  }, [renderedHtml]);

  return zLayoutProps ? <ZLayoutBlock {...zLayoutProps} /> : null;
};

ZLayoutProxy.displayName = 'AcfZLayoutBlock';

export default ZLayoutProxy;
