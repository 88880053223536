import { useState, useEffect } from 'react';

import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';
import RecommendedClassesBlock from '../components/Blocks/RecommendedClassesBlock';

const RecommendedClassesBlockProxy = ({ renderedHtml }) => {
  const [classes, setClasses] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      setClasses(getObjDataFromRenderedHtml(renderedHtml));
    }
  }, [renderedHtml]);

  return <RecommendedClassesBlock classes={classes} />;
};

RecommendedClassesBlockProxy.displayName = 'AcfRecommendedClassesBlock';

export default RecommendedClassesBlockProxy;
