import React from 'react';

const IconHome = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.02424 13.1327V8.94797H9.37204V13.1327C9.37204 13.593 9.74867 13.9697 10.209 13.9697H12.7198C13.1802 13.9697 13.5568 13.593 13.5568 13.1327V7.27407H14.9796C15.3646 7.27407 15.5487 6.79701 15.2558 6.54593L8.2589 0.243696C7.94086 -0.0408673 7.45542 -0.0408673 7.13738 0.243696L0.140484 6.54593C-0.144079 6.79701 0.0316801 7.27407 0.416677 7.27407H1.83949V13.1327C1.83949 13.593 2.21612 13.9697 2.67644 13.9697H5.18729C5.64761 13.9697 6.02424 13.593 6.02424 13.1327Z"
        fill="#002333"
      />
    </svg>
  );
};

export default IconHome;
