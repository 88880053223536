import Image from 'next/image';
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel';
import { ReactNode } from 'react';

import arrowRight from 'public/images/chev_right.svg';
import arrowLeft from 'public/images/chev_left.svg';

import 'node_modules/pure-react-carousel/dist/react-carousel.es.css';

type DotStyle = 'small' | 'large';

interface CarouselProps {
  dotStyle?: DotStyle;
  items: JSX.Element[];
  naturalSlideWidth: number;
}

const Carousel = ({
  dotStyle = 'small',
  items,
  naturalSlideWidth,
}: CarouselProps) => {
  return (
    <CarouselProvider
      data-testid="carousel"
      dragEnabled
      naturalSlideHeight={0}
      naturalSlideWidth={naturalSlideWidth}
      isIntrinsicHeight
      totalSlides={items.length}
      touchEnabled
    >
      <Slider>{items as ReactNode}</Slider>
      <CarouselPagination dotStyle={dotStyle} length={items.length} />
    </CarouselProvider>
  );
};

interface CarouselPaginationProps {
  dotStyle: DotStyle;
  length: number;
}

const CarouselPagination = ({ dotStyle, length }: CarouselPaginationProps) => {
  const dots = [];
  const dotStyles = dotStyle === 'small' ? 'mx-2 h-6 w-6' : 'mx-4 h-8 w-8';
  const buttonStyles = 'hidden md:block';
  for (let i = 0; i < length; i++) {
    dots.push(
      <Dot
        slide={i}
        key={i}
        className={`${dotStyles} bg-[rgb(220,222,225)] rounded-full disabled:bg-violet`}
      ></Dot>
    );
  }
  return (
    <div
      data-testid="carousel-pagination"
      className="flex w-full justify-center mt-[16px]"
    >
      <div className={buttonStyles}>
        <ButtonBack>
          <PaginationArrowButton direction="prev" />
        </ButtonBack>
      </div>
      <div
        data-testid="carousel-dots"
        className="mx-[2px] self-stretch flex items-center"
      >
        {dots}
      </div>
      <div className={buttonStyles}>
        <ButtonNext>
          <PaginationArrowButton direction="next" />
        </ButtonNext>
      </div>
    </div>
  );
};

export default Carousel;

interface PaginationArrowButtonProps {
  direction: 'next' | 'prev';
}

const PaginationArrowButton = ({ direction }: PaginationArrowButtonProps) => {
  return (
    <span className="items-center bg-white rounded-[50%] border-solid border-[1px] shadow-[0_2px_4px_0_rgb(187_187_187_/50%)] text-[#dcdee1] cursor-pointer flex text-[1.5rem] h-[40px] justify-center overflow-visible text-center w-[40px] transition-[background-color_150ms_cubic-bezier(0.4,0,0.2,1)_0ms]">
      <Image
        alt={direction}
        width="22"
        height="22"
        src={direction === 'next' ? arrowRight : arrowLeft}
      />
    </span>
  );
};
