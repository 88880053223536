//footer menus

export const footerMenus = {
  en: [
    {
      title: 'Company',
      items: [
        {
          title: 'About',
          url: 'https://www.skillshare.com/about',
          trackingId: 'about',
        },
        {
          title: 'Careers',
          url: 'https://www.skillshare.com/careers',
          trackingId: 'careers',
        },
        {
          title: 'Press',
          url: 'https://www.skillshare.com/press',
          trackingId: 'press',
        },
        {
          title: 'Blog',
          url: 'https://www.skillshare.com/blog/',
          trackingId: 'blog',
        },
        {
          title: 'Affiliate Program',
          url: 'https://www.skillshare.com/affiliates',
          trackingId: 'affiliates',
        },
        {
          title: 'Partnerships',
          url: 'https://join.skillshare.com/brandpartnerships/',
          trackingId: 'partnerships',
        },
      ],
    },
    {
      title: 'Community',
      items: [
        {
          title: 'Team Plans',
          url: 'https://teams.skillshare.com/',
          trackingId: 'teams',
        },
        {
          title: 'Gift Membership Cards',
          url: 'https://join.skillshare.com/gift-membership/',
          trackingId: 'gift-membership',
        },
        {
          title: 'Corporate Gift Cards',
          url: 'https://join.skillshare.com/corporate-gift-cards',
          trackingId: 'corporate-gift-cards',
        },
        {
          title: 'Scholarships',
          url: 'https://www.skillshare.com/scholarships',
          trackingId: 'scholarships',
        },
      ],
    },
    {
      title: 'Teaching',
      items: [
        {
          title: 'Become a Teacher',
          url: 'https://www.skillshare.com/teach',
          trackingId: 'teach',
        },
        {
          title: 'Teacher Help Center',
          url: 'https://help.skillshare.com/hc/categories/200244167',
          trackingId: 'teacher-help-center',
        },
      ],
    },
  ],
  es: [
    {
      title: 'Comunidad',
      items: [
        {
          title: 'Planes institucionales',
          url: 'https://teams.skillshare.com/',
          trackingId: 'teams',
        },
        {
          title: 'Tarjetas de membresía de regalo',
          url: 'https://join.skillshare.com/gift-membership/',
          trackingId: 'gift-membership',
        },
        {
          title: 'Tarjetas de regalo corporativas',
          url: 'https://join.skillshare.com/corporate-gift-cards',
          trackingId: 'corporate-gift-cards',
        },
        {
          title: 'Becas de estudio',
          url: 'https://www.skillshare.com/scholarships',
          trackingId: 'scholarships',
        },
      ],
    },
    {
      title: 'Skillshare',
      items: [
        {
          title: 'Nosotros',
          url: 'https://www.skillshare.com/about',
          trackingId: 'about',
        },
        {
          title: 'Empleo',
          url: 'https://www.skillshare.com/careers',
          trackingId: 'careers',
        },
        {
          title: 'Prensa',
          url: 'https://www.skillshare.com/press',
          trackingId: 'press',
        },
        {
          title: 'Blog',
          url: 'https://www.skillshare.com/blog/',
          trackingId: 'blog',
        },
        {
          title: 'Programa de Afiliados',
          url: 'https://www.skillshare.com/affiliates',
          trackingId: 'affiliates',
        },
        {
          title: 'Alianzas',
          url: 'https://join.skillshare.com/brandpartnerships/',
          trackingId: 'partnerships',
        },
      ],
    },
    {
      title: 'Enseñar',
      items: [
        {
          title: 'Enseñar en Skillshare',
          url: 'https://www.skillshare.com/teach',
          trackingId: 'teach',
        },
        {
          title: 'Centro de ayuda para profesores',
          url: 'https://help.skillshare.com/hc/categories/200244167',
          trackingId: 'teacher-help-center',
        },
      ],
    },
  ],
  fr: [
    {
      title: 'Communauté',
      items: [
        {
          title: 'Skillshare pour les institutions',
          url: 'https://teams.skillshare.com/',
          trackingId: 'teams',
        },
        {
          title: 'Cartes d’abonnement cadeau',
          url: 'https://join.skillshare.com/gift-membership/',
          trackingId: 'gift-membership',
        },
        {
          title: 'Cartes cadeaux d’entreprise',
          url: 'https://join.skillshare.com/corporate-gift-cards',
          trackingId: 'corporate-gift-cards',
        },
        {
          title: 'Bourses d’études',
          url: 'https://www.skillshare.com/scholarships',
          trackingId: 'scholarships',
        },
      ],
    },
    {
      title: 'Skillshare',
      items: [
        {
          title: 'À propos',
          url: 'https://www.skillshare.com/about',
          trackingId: 'about',
        },
        {
          title: 'Carrières',
          url: 'https://www.skillshare.com/careers',
          trackingId: 'careers',
        },
        {
          title: 'Presse',
          url: 'https://www.skillshare.com/press',
          trackingId: 'press',
        },
        {
          title: 'Blog',
          url: 'https://www.skillshare.com/blog/',
          trackingId: 'blog',
        },
        {
          title: 'Programme d’affiliation',
          url: 'https://www.skillshare.com/affiliates',
          trackingId: 'affiliates',
        },
        {
          title: 'Partenariats',
          url: 'https://join.skillshare.com/brandpartnerships/',
          trackingId: 'partnerships',
        },
      ],
    },
    {
      title: 'Enseigner',
      items: [
        {
          title: 'Devenir enseignant',
          url: 'https://www.skillshare.com/teach',
          trackingId: 'teach',
        },
        {
          title: 'Centre d’aide aux enseignants',
          url: 'https://help.skillshare.com/hc/categories/200244167',
          trackingId: 'teacher-help-center',
        },
      ],
    },
  ],
  de: [
    {
      title: 'Community',
      items: [
        {
          title: 'Team-Pläne',
          url: 'https://teams.skillshare.com/',
          trackingId: 'teams',
        },
        {
          title: 'Geschenk-Mitgliedskarten',
          url: 'https://join.skillshare.com/gift-membership/',
          trackingId: 'gift-membership',
        },
        {
          title: 'Firmen-Gutscheine',
          url: 'https://join.skillshare.com/corporate-gift-cards',
          trackingId: 'corporate-gift-cards',
        },
        {
          title: 'Stipendien',
          url: 'https://www.skillshare.com/scholarships',
          trackingId: 'scholarships',
        },
      ],
    },
    {
      title: 'Unternehmen',
      items: [
        {
          title: 'Über',
          url: 'https://www.skillshare.com/about',
          trackingId: 'about',
        },
        {
          title: 'Jobs',
          url: 'https://www.skillshare.com/careers',
          trackingId: 'careers',
        },
        {
          title: 'Presse',
          url: 'https://www.skillshare.com/press',
          trackingId: 'press',
        },
        {
          title: 'Blog',
          url: 'https://www.skillshare.com/blog/',
          trackingId: 'blog',
        },
        {
          title: 'Affiliate-Programm',
          url: 'https://www.skillshare.com/affiliates',
          trackingId: 'affiliates',
        },
        {
          title: 'Partnerschaften',
          url: 'https://join.skillshare.com/brandpartnerships/',
          trackingId: 'partnerships',
        },
      ],
    },
    {
      title: 'Unterrichten',
      items: [
        {
          title: 'Kurse leiten',
          url: 'https://www.skillshare.com/teach',
          trackingId: 'teach',
        },
        {
          title: 'Teacher Help Center',
          url: 'https://help.skillshare.com/hc/categories/200244167',
          trackingId: 'teacher-help-center',
        },
      ],
    },
  ],
  pt: [
    {
      title: 'Comunidade',
      items: [
        {
          title: 'Planos para equipes',
          url: 'https://teams.skillshare.com/',
          trackingId: 'teams',
        },
        {
          title: 'Vale-assinatura de presente',
          url: 'https://join.skillshare.com/gift-membership/',
          trackingId: 'gift-membership',
        },
        {
          title: 'Cartões-presente corporativos',
          url: 'https://join.skillshare.com/corporate-gift-cards',
          trackingId: 'corporate-gift-cards',
        },
        {
          title: 'Bolsas de estudo',
          url: 'https://www.skillshare.com/scholarships',
          trackingId: 'scholarships',
        },
      ],
    },
    {
      title: 'A empresa',
      items: [
        {
          title: 'Sobre nós',
          url: 'https://www.skillshare.com/about',
          trackingId: 'about',
        },
        {
          title: 'Carreiras',
          url: 'https://www.skillshare.com/careers',
          trackingId: 'careers',
        },
        {
          title: 'Imprensa',
          url: 'https://www.skillshare.com/press',
          trackingId: 'press',
        },
        {
          title: 'Blog',
          url: 'https://www.skillshare.com/blog/',
          trackingId: 'blog',
        },
        {
          title: 'Programa de Afiliados',
          url: 'https://www.skillshare.com/affiliates',
          trackingId: 'affiliates',
        },
        {
          title: 'Parcerias',
          url: 'https://join.skillshare.com/brandpartnerships/',
          trackingId: 'partnerships',
        },
      ],
    },
    {
      title: 'Ensino',
      items: [
        {
          title: 'Torne-se um professor',
          url: 'https://www.skillshare.com/teach',
          trackingId: 'teach',
        },
        {
          title: 'Suporte ao Professor',
          url: 'https://help.skillshare.com/hc/categories/200244167',
          trackingId: 'teacher-help-center',
        },
      ],
    },
  ],
};

const openPrivacyChoicesBanner = (event) => {
  event.preventDefault();

  /* @ts-expect-error Datagrail Api should be initialized first*/
  if (window !== undefined && window.DG_BANNER_API !== undefined) {
    /* @ts-expect-error Datagrail Api should be initialized first*/
    window.DG_BANNER_API.showConsentBanner();
  }
};

//footer utility menus
export const footerUtilityMenus = {
  en: [
    {
      title: 'Help',
      url: 'http://help.skillshare.com/',
      trackingId: 'help',
    },
    {
      title: 'Privacy',
      url: 'https://www.skillshare.com/privacy',
      trackingId: 'privacy',
    },
    {
      title: 'Terms',
      url: 'https://www.skillshare.com/terms',
      trackingId: 'terms',
    },
    {
      title: 'Your Privacy Choices',
      url: '#privacy-choices',
      trackingId: 'privacy-choices',
      clickHandler: openPrivacyChoicesBanner,
    },
    {
      title: 'Notice to CA Residents',
      url: 'https://www.skillshare.com/privacy#privacy-ccpa',
      trackingId: 'privacy-choices',
    },
  ],
  es: [
    {
      title: 'Ayuda',
      url: 'http://help.skillshare.com/',
      trackingId: 'help',
    },
    {
      title: 'Privacidad',
      url: 'https://www.skillshare.com/privacy',
      trackingId: 'privacy',
    },
    {
      title: 'Términos',
      url: 'https://www.skillshare.com/terms',
      trackingId: 'terms',
    },
    {
      title: 'Tus opciones de privacidad',
      url: '#privacy-choices',
      trackingId: 'privacy-choices',
      clickHandler: openPrivacyChoicesBanner,
    },
    {
      title: 'Aviso a los residentes de CA',
      url: 'https://www.skillshare.com/privacy#privacy-ccpa',
      trackingId: 'privacy-choices',
    },
  ],
  fr: [
    {
      title: 'Aide',
      url: 'http://help.skillshare.com/',
      trackingId: 'help',
    },
    {
      title: 'Confidentialité',
      url: 'https://www.skillshare.com/privacy',
      trackingId: 'privacy',
    },
    {
      title: 'CGU',
      url: 'https://www.skillshare.com/terms',
      trackingId: 'terms',
    },
    {
      title: 'Vos choix en matière de confidentialité',
      url: '#privacy-choices',
      trackingId: 'privacy-choices',
      clickHandler: openPrivacyChoicesBanner,
    },
    {
      title: 'Avis pour les résidents de Californie',
      url: 'https://www.skillshare.com/privacy#privacy-ccpa',
      trackingId: 'privacy-choices',
    },
  ],
  de: [
    {
      title: 'Hilfe',
      url: 'http://help.skillshare.com/',
      trackingId: 'help',
    },
    {
      title: 'Datenschutz',
      url: 'https://www.skillshare.com/privacy',
      trackingId: 'privacy',
    },
    {
      title: 'Nutzungsbedingungen',
      url: 'https://www.skillshare.com/terms',
      trackingId: 'terms',
    },
    {
      title: 'Deine Einstellungen zum Datenschutz',
      url: '#privacy-choices',
      trackingId: 'privacy-choices',
      clickHandler: openPrivacyChoicesBanner,
    },
    {
      title: 'Hinweis für alle, die in CA ansässig sind',
      url: 'https://www.skillshare.com/privacy#privacy-ccpa',
      trackingId: 'privacy-choices',
    },
  ],
  pt: [
    {
      title: 'Ajuda',
      url: 'http://help.skillshare.com/',
      trackingId: 'help',
    },
    {
      title: 'Privacidade',
      url: 'https://www.skillshare.com/privacy',
      trackingId: 'privacy',
    },
    {
      title: 'Termos',
      url: 'https://www.skillshare.com/terms',
      trackingId: 'terms',
    },
    {
      title: 'Suas opções de privacidade',
      url: '#privacy-choices',
      trackingId: 'privacy-choices',
      clickHandler: openPrivacyChoicesBanner,
    },
    {
      title: 'Aviso aos residentes da Califórnia',
      url: 'https://www.skillshare.com/privacy#privacy-ccpa',
      trackingId: 'privacy-choices',
    },
  ],
};

export const filterContentTypes = [
  'Industry Report',
  'eBook',
  'Guide',
  'Deep Dive',
];
