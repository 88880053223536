import { Post } from 'gql/graphql';
import PostCard from 'components/Cards/PostCard';

export interface FeaturedPostsBlockProps extends Blog.BlockProps {
  posts: Post[];
}

const FeaturedPostsBlock = (props: FeaturedPostsBlockProps) => {
  const { anchor, className, posts } = props;

  if (!posts) {
    return <></>;
  }

  return (
    <div
      id={anchor}
      data-testid="featured-posts-block"
      className={`${className ?? ''} featured-posts-block lg:grid lg:pb-[8px] lg:gap-x-8 lg:gap-y-[32px] lg:grid-cols-4`}
    >
      {Object.entries(posts).map((cur, ind) => {
        if (cur[1]) {
          return (
            <PostCard
              key={ind}
              post={cur[1]}
              locale={cur[1].locale.locale.slice(0, 2) as Blog.LocaleType}
              showPill={true}
              styles="!mb-8 lg:!mb-0 lg:!col-span-2"
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default FeaturedPostsBlock;
