import { useMemo } from 'react';

import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';
import ResourceGridBlock, {
  ResourceGridBlockProps,
} from '../components/Blocks/ResourceGridBlock';

const ResourceGridBlockProxy = ({ renderedHtml }) => {
  const resourceGridProps = useMemo(() => {
    if (renderedHtml) {
      return getObjDataFromRenderedHtml(renderedHtml) as ResourceGridBlockProps;
    }
    return null;
  }, [renderedHtml]);

  return resourceGridProps ? (
    <ResourceGridBlock {...resourceGridProps} />
  ) : null;
};

ResourceGridBlockProxy.displayName = 'AcfResourceGridBlock';

export default ResourceGridBlockProxy;
