import Image from 'next/image';
import React from 'react';

interface AuthorAvatarProps {
  author: null | {
    profilePic: string;
    displayName: string;
  };
  className?: string;
}

const AuthorAvatar = ({ author, className }: AuthorAvatarProps) => {
  return (
    author?.profilePic && (
      <div
        className={`border-white border-2 rounded-full overflow-hidden h-[32px] w-[32px] relative ${className ?? ''}`}
      >
        <Image
          objectFit="cover"
          src={author?.profilePic}
          alt={author.displayName}
          layout="fill"
          loading="lazy"
        />
      </div>
    )
  );
};

export default AuthorAvatar;
