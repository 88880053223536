import { useState, useEffect } from 'react';

import InfographicBlock from 'components/Blocks/InfographicBlock';

import { getObjDataFromRenderedHtml } from '../helpers/getDataFromRenderedHtml';

const InfographicBlockProxy = ({ renderedHtml }) => {
  const [infographicProps, setProps] = useState(null);

  useEffect(() => {
    if (renderedHtml) {
      setProps(getObjDataFromRenderedHtml(renderedHtml));
    }
  }, [renderedHtml]);

  return infographicProps ? <InfographicBlock {...infographicProps} /> : null;
};

InfographicBlockProxy.displayName = 'AcfInfographicBlock';

export default InfographicBlockProxy;
